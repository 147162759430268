<template>
  <checkbox-select v-model="inputVal" v-bind="$attrs" />
</template>

<script>
  import selectStore from '../../../store/modules/global/filters/select';
  import checkboxSelect from './../../base/forms/checkbox_select';

  export default {
    name: 'CheckboxMagic',
    components: {
      checkboxSelect
    },
    props: {
      moduleNamespace: {
        type: String,
        required: true
      },
      value: Array
    },
    computed: {
      inputVal: {
        get() {
          return this.$store.getters[this.moduleNamespace + '/getValue'];
        },
        set(value) {
          return this.$store.commit(this.moduleNamespace + '/setValue', value);
        }
      }
    },
    methods: {},
    created() {
      this.magicModule(this.moduleNamespace, selectStore);
      this.$store.commit(this.moduleNamespace + '/setValue', this.value);
    }
  };
</script>
