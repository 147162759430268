<template>
  <fieldset style="position: relative" data-test="medications-by-ndc-fieldset">
    <span
      title="remove"
      class="fs-20 fw-700"
      style="position: absolute; right: 8px; top: 0; cursor: pointer"
      v-on:click="$emit('delete')"
      >x</span
    >
    <legend>{{ fieldLabel }}</legend>
    <div class="flex">
      <single-select
        class="lg:col-span-3 flex-1"
        v-model="operatorVal"
        :options="operatorOptions"
        :clearable="false"
        required
        @input="
          $emit('operator', operatorVal, filterKey);
          $emit('update-population');
        "
      />
      <button
        type="button"
        class="btn btn-primary flex-1 rfs-m-4-l"
        @click="
          showModal();
          storeResetValue();
        "
        :disabled="!selectButtonReady"
      >
        <span v-if="!this.inputVal.inputVal">Select</span
        ><span v-else>Edit</span> Codes
      </button>
    </div>
    <portal to="modal">
      <modal
        data-test="add-medication-by-ndc-modal"
        :name="modalName"
        width="100%"
        height="auto"
        :adaptive="true"
        classes="rfs-p-8"
        :clickToClose="false"
      >
        <h3 class="modal-title">
          <span v-if="!this.inputVal.inputVal">Select</span
          ><span v-else>Edit</span> Drugs by NDC
        </h3>
        <code-lookup
          :value="value"
          v-bind="lookupProps"
          v-model.trim="inputVal"
          @table-ready="selectionModalReady"
          @input="checkForChanges()"
        >
        </code-lookup>
        <div v-if="mustCreateGroup" class="flex flex-row-reverse items-end">
          <p class="rfs-m-8-t rfs-m-4-r order-3">
            You must save selections greater than 9 as a Medication Group.
          </p>
          <text-input
            data-test="group-name-input"
            id="groupName"
            class="order-2 rfs-m-4-r"
            v-model="groupName"
            fieldLabel="Group Name Required"
          />
          <button
            data-test="save-as-group"
            type="button"
            class="btn rfs-m-8-t order-1"
            @click="saveAndClose"
          >
            Save as Group
          </button>
        </div>
        <button
          type="button"
          class="float-left btn rfs-m-8-t"
          @click="
            hideModal();
            cancelSelection();
            checkForChanges();
          "
        >
          Cancel
        </button>
        <button
          :disabled="noChange"
          v-if="!mustCreateGroup"
          type="button"
          class="float-right btn rfs-m-8-t"
          @click="updateStatistics()"
        >
          Update Statistics
        </button>
      </modal>
    </portal>
    <ul class="rfs-m-4-t fs-14">
      <li v-for="(selected, i) in inputVal.inputVal" :key="i">
        {{ selected.ndc }}: {{ selected.ndcDescription }}
        <!-- {{ selected.code }}: {{ selected.description }} -->
        <div v-if="i != inputVal.inputVal.length - 1">{{ joiningBy }}</div>
      </li>
    </ul>
  </fieldset>
</template>

<script>
  import selectStore from '../../../store/modules/global/filters/select';
  import singleSelect from '../../../components/base/forms/single_select';
  import CodeLookup from '../CodeLookup';
  import TextInput from '../../../components/base/forms/text_input';
  import isEqual from 'lodash/isEqual';

  export default {
    name: 'NdcSelectMagic',
    components: {
      CodeLookup,
      singleSelect,
      TextInput
    },
    props: {
      filterKey: Number,
      modalName: {
        type: String,
        default: 'ndc_code_modal_' + Math.floor(Math.random() * 1000 + 1)
      },
      value: {
        type: Object
      },
      moduleNamespace: {
        type: String,
        required: true
      },
      codeTypes: {
        type: Array,
        default() {
          return [
            {
              id: 'ndcsv2',
              apiPath: 'ndcsv2',
              name: 'NDC'
            }
          ];
        }
      },
      fieldLabel: {
        type: String
      },
      operator: {
        type: String,
        default: 'includes'
      },
      logicalOperator: {
        type: String,
        default: 'or'
      }
    },
    data() {
      return {
        lookupProps: {
          searchId: 'searchCodeNdc',
          searchLabel: 'Drug Lookup',
          codeTypes: this.codeTypes,
          uniqueIdentifier: 'ndc',
          columns: [
            {
              text: 'NDC',
              datafield: 'ndc',
              pinned: true,
              width: 125
            },
            {
              text: 'NDC Description',
              datafield: 'ndcDescription',
              pinned: true,
              width: 200
            },
            {
              text: 'GPI',
              datafield: 'drugGpi',
              width: 150
            },
            {
              text: 'GPI Name',
              datafield: 'drugGpiName',
              width: 200
            },
            {
              text: 'USC',
              datafield: 'drugUsc',
              width: 80
            },
            {
              text: 'USC Description',
              datafield: 'drugUscDescription',
              width: 200
            },
            {
              text: 'AHFS Class',
              datafield: 'drugAhfsClass',
              width: 100
            },
            {
              text: 'AHFS Class Description',
              datafield: 'drugAhfsClassDescription',
              width: 200
            },
            {
              text: 'Manufacturer Name',
              datafield: 'drugMfrName',
              width: 200
            }
          ],
          initialSort: {
            column: 'ndcDescription',
            direction: 'asc'
          },
          showSelectAllButtons: false
        },
        groupName: '',
        operatorOptions: ['includes', 'excludes'],
        operatorVal: this.operator,
        selectionModalIsReady: false,
        selectButtonReady: false,
        noChange: true
      };
    },
    computed: {
      inputVal: {
        get() {
          return this.$store.getters[this.moduleNamespace + '/getValue'];
        },
        set(value) {
          return this.$store.commit(this.moduleNamespace + '/setValue', value);
        }
      },
      joiningBy() {
        return this.logicalOperator;
      },
      selectedCount() {
        return this.$store.getters[this.moduleNamespace + '/getValue'].inputVal
          .length;
      },
      mustCreateGroup() {
        return this.selectedCount > 9;
      }
    },
    methods: {
      selectionModalReady() {
        this.selectionModalIsReady = true;
      },
      inputValSet(value) {
        this.$store.commit(this.moduleNamespace + '/setValue', value);
      },
      storeResetValue() {
        this.resetValue =
          this.$store.getters[this.moduleNamespace + '/getValue'];
      },
      checkForChanges() {
        var selected = this.inputVal.inputVal.map(function (item) {
          return item.code;
        });
        var reset = this.resetValue.inputVal.map(function (item) {
          return item.code;
        });
        this.noChange = isEqual(selected, reset);
      },
      cancelSelection() {
        this.inputVal = this.resetValue;
        if (
          !this.inputVal ||
          !this.inputVal.inputVal ||
          this.inputVal.inputVal.length === 0
        ) {
          this.$emit('empty-cancel');
        }
      },
      updateStatistics() {
        if (
          !this.inputVal ||
          !this.inputVal.inputVal ||
          this.inputVal.inputVal.length === 0
        ) {
          this.hideModal();
          this.$emit('delete');
        } else {
          this.hideModal();
          this.$emit('update-population');
        }
      },
      showModal() {
        const modalName = this.modalName;
        this.$modal.show(modalName);
      },
      hideModal() {
        const modalName = this.modalName;
        this.$modal.hide(modalName);
        this.$emit('scroll-check');
      },
      saveAndClose() {
        // if group we need to swap out filter to group
        if (this.groupName == '') {
          this.$snotify.error('Please name the group.', {
            timeout: 4000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true
          });
          return;
        }

        let ndcs = [];
        this.inputVal.inputVal.map((obj) => {
          ndcs.push(obj.ndc);
        });
        this.$store
          .dispatch('medicationGroupsManagement/save', {
            data: {
              name: this.groupName,
              ndcs: ndcs
            }
          })
          .then((response) => {
            this.hideModal();
            // cancel original NDC box
            this.$emit('empty-cancel');
            this.$emit('swap-filter', [
              {
                name: 'drugGroups',
                valueCodes: [
                  {
                    code: response.data.id.toString(),
                    description: this.groupName
                  }
                ]
              }
            ]);
          });
      },
      addNDCDetails() {
        let storedVals =
          this.$store.getters[this.moduleNamespace + '/getValue'].inputVal;
        let ndcs = [];

        for (let i in storedVals) {
          ndcs.push(storedVals[i].ndc);
        }

        if (ndcs.length > 0) {
          ndcs = ndcs.join();

          this.$http({
            url: '/data-service/ndcList/',
            params: { ndcList: ndcs }
          })
            .then((response) => {
              for (let i in storedVals) {
                for (let j in response.data) {
                  if (storedVals[i].ndc == response.data[j].ndc) {
                    storedVals[i] = response.data[j];
                    break;
                  }
                }
              }

              this.$store.commit(this.moduleNamespace + '/setValue', {
                inputVal: storedVals
              });
            })
            .finally(() => {
              this.selectButtonReady = true;
            });
        } else {
          this.selectButtonReady = true;
        }
      }
    },
    created() {
      this.magicModule(this.moduleNamespace, selectStore);
      this.$store.commit(this.moduleNamespace + '/setValue', this.value);

      this.addNDCDetails();
    },
    mounted() {
      if (
        !this.inputVal ||
        !this.inputVal.inputVal ||
        this.inputVal.inputVal.length === 0
      ) {
        // got to give the portal a spit second to load up
        var _this = this;
        setTimeout(function () {
          _this.storeResetValue();
          _this.showModal();
          _this.$watch('inputVal', (n) => {
            _this.checkForChanges();
          });
        }, 200);
      }
    }
  };
</script>
