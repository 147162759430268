<template>
  <fieldset style="position: relative"
  data-test="add-therapeutic-class-fieldset">
    <span
      title="remove"
      class="fs-20 fw-700"
      style="position: absolute; right: 8px; top: 0; cursor: pointer"
      v-on:click="$emit('delete')"
      >x</span
    >
    <legend>{{ fieldLabel }}</legend>
    <div class="flex">
      <single-select
        class="lg:col-span-3 flex-1"
        v-model="operatorVal"
        :options="operatorOptions"
        :clearable="false"
        required
        @input="
          $emit('operator', operatorVal, filterKey);
          $emit('update-population');
        "
      />
      <button
        type="button"
        class="btn btn-primary flex-1 rfs-m-4-l"
        @click="
          showModal();
          storeResetValue();
          checkForChanges();
        "
      >
        <span v-if="!this.inputVal.inputVal">Select</span
        ><span v-else>Edit</span> Codes
      </button>
    </div>
    <portal to="modal">
      <modal
        data-test="add-therapeutic-class-modal"
        :name="modalName"
        width="100%"
        height="auto"
        :adaptive="true"
        classes="rfs-p-8"
        :clickToClose="false"
      >
        <h3 class="modal-title">
          <span v-if="!this.inputVal.inputVal">Select</span
          ><span v-else>Edit</span> Therapeutic Class
        </h3>
        <code-lookup
          v-bind="lookupProps"
          v-model.trim="inputVal"
          @input="checkForChanges()"
        >
        </code-lookup>
        <button
          type="button"
          class="float-left btn rfs-m-8-t"
          @click="
            hideModal();
            cancelSelection();
          "
        >
          Cancel
        </button>
        <button
          :disabled="noChange"
          type="button"
          class="float-right btn rfs-m-8-t"
          @click="updateStatistics()"
        >
          Update Statistics
        </button>
      </modal>
    </portal>
    <ul class="rfs-m-4-t fs-14">
      <li v-for="(selected, i) in inputVal.inputVal" :key="i">
        {{ selected.code }}: {{ selected.description }}
        <div v-if="i != inputVal.inputVal.length - 1">{{ joiningBy }}</div>
      </li>
    </ul>
  </fieldset>
</template>

<script>
  import selectStore from '../../../store/modules/global/filters/select';
  import singleSelect from '../../../components/base/forms/single_select';
  import CodeLookup from '../CodeLookup';
  import isEqual from 'lodash/isEqual';

  export default {
    name: 'TherapeuticClassSelectMagic',
    components: {
      CodeLookup,
      singleSelect
    },
    props: {
      filterKey: Number,
      modalName: {
        type: String,
        default: 'tc_code_modal_' + Math.floor(Math.random() * 1000 + 1)
      },
      value: {
        type: Object
      },
      moduleNamespace: {
        type: String,
        required: true
      },
      codeTypes: {
        type: Array,
        default() {
          return [
            {
              id: 'ahfs',
              apiPath: 'ahfs',
              name: 'AHFS'
            },
            {
              id: 'usc',
              apiPath: 'uscs',
              name: 'USC'
            }
          ];
        }
      },
      fieldLabel: {
        type: String
      },
      operator: {
        type: String,
        default: 'includes'
      },
      logicalOperator: {
        type: String,
        default: 'or'
      }
    },
    data() {
      return {
        lookupProps: {
          searchId: 'searchCodeUsc',
          searchLabel: 'Therapeutic Class Lookup',
          codeTypes: this.codeTypes
        },
        operatorOptions: ['includes', 'excludes'],
        operatorVal: this.operator,
        noChange: true
      };
    },
    computed: {
      inputVal: {
        get() {
          return this.$store.getters[this.moduleNamespace + '/getValue'];
        },
        set(value) {
          return this.$store.commit(this.moduleNamespace + '/setValue', value);
        }
      },
      joiningBy() {
        return this.logicalOperator;
      }
    },
    methods: {
      storeResetValue() {
        this.resetValue =
          this.$store.getters[this.moduleNamespace + '/getValue'];
      },
      checkForChanges() {
        var selected = this.inputVal.inputVal.map(function (item) {
          return item.code;
        });
        var reset = this.resetValue.inputVal.map(function (item) {
          return item.code;
        });
        this.noChange = isEqual(selected, reset);
      },
      cancelSelection() {
        this.inputVal = this.resetValue;
        if (
          !this.inputVal ||
          !this.inputVal.inputVal ||
          this.inputVal.inputVal.length === 0
        ) {
          this.$emit('empty-cancel');
        }
      },
      updateStatistics() {
        if (
          !this.inputVal ||
          !this.inputVal.inputVal ||
          this.inputVal.inputVal.length === 0
        ) {
          this.hideModal();
          this.$emit('delete');
        } else {
          this.hideModal();
          this.$emit('update-population');
        }
      },
      showModal() {
        const modalName = this.modalName;
        this.$modal.show(modalName);
      },
      hideModal() {
        const modalName = this.modalName;
        this.$modal.hide(modalName);
        this.$emit('scroll-check');
      }
    },
    created() {
      this.magicModule(this.moduleNamespace, selectStore);
      this.$store.commit(this.moduleNamespace + '/setValue', this.value);
    },
    mounted() {
      if (
        !this.inputVal ||
        !this.inputVal.inputVal ||
        this.inputVal.inputVal.length === 0
      ) {
        // got to give the portal a spit second to load up
        var _this = this;
        setTimeout(function () {
          _this.storeResetValue();
          _this.showModal();
          _this.$watch('inputVal', (n) => {
            _this.checkForChanges();
          });
        }, 200);
      }
    }
  };
</script>
