<template>
  <div>
    <div class="flex flex-row-reverse">
      <div v-if="multiType">
        <radio-select
          class="rfs-m-4-l rfs-m-0-t code-type-choice rfs-p-2-l rfs-p-2-r"
          v-model="apiPath"
          :options="codeTypes"
          optionLabel="name"
          optionValue="apiPath"
          asRow
        />
      </div>
      <text-input
        :id="searchId"
        v-model="inputVal"
        :fieldLabel="searchLabel"
        v-on:input="onSearch"
        class="flex-grow"
        type="search"
        v-on:keydown.enter.native.prevent
      />
    </div>
    <!--
      :pageable="true"
      :pagerMode="'advanced'"
      :pageSizeMode="'root'"
      :pageSizeOptions="['10', '20', '50']"
     -->
    <!-- {{ lookupVal }} -->
    <div
      class="
        grid grid-cols-2
        gap-8
        selection-table
        crca-datatable
        rfs-m-4-t
        selection-table
      "
    >
      <loader ref="miamiLoader" fullScreen />
      <div>
        <h3 class="fs-16 fw-sb rfs-m-2-b">Lookup Results</h3>
        <button
          class="btn btn-text"
          v-if="showSelectAllButtons"
          @click="onSelectAllSearch"
          type="button"
        >
          Select All <font-awesome-icon icon="arrow-up" rotation="90" />
        </button>
        <div v-if="maximumSelectionLimitReached">
          A maximum of {{ maximumSelectable }} items may be selected.
        </div>
        <JqxGrid
          :ref="refSearch"
          :source="dataAdapterSearch"
          :columns="columns"
          :columnsresize="true"
          :sortable="sortableSearch"
          :class="{ sortable: sortableSearch }"
          width="100%"
          selectionmode="singlerow"
          :ready="searchTableReady"
          @rowselect="onSearchRowSelect($event)"
          @sort="customSearchSort($event)"
          :height="300"
          :groupable="groupable"
          :filterable="true"
        />
      </div>
      <div>
        <h3 class="fs-16 fw-sb rfs-m-2-b">Selected : {{ selectedRowCount }}</h3>
        <button
          class="btn btn-text"
          v-if="showSelectAllButtons"
          @click="onSelectNoneSelect"
          type="button"
        >
          <font-awesome-icon icon="arrow-up" rotation="270" /> Deselect All
        </button>
        <JqxGrid
          :ref="refSelect"
          :source="dataAdapterSelect"
          :columns="columns"
          :columnsresize="true"
          :sortable="sortableSelect"
          :class="{ sortable: sortableSelect }"
          width="100%"
          selectionmode="singlerow"
          :ready="selectTableReady"
          @rowselect="onSelectedRowSelect($event)"
          @sort="customSelectSort($event)"
          :height="300"
          :groupable="groupable"
          :filterable="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import TextInput from '../base/forms/text_input';
  import debounce from 'lodash/debounce';
  // jqx
  import JqxGrid from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid';
  import RadioSelect from './../base/forms/radio_select';
  import { SnotifyPosition } from 'vue-snotify';

  export default {
    name: 'CodeLookup',
    components: {
      TextInput,
      JqxGrid,
      RadioSelect
    },
    props: {
      searchId: {
        type: String
      },
      searchLabel: {
        type: String,
        require: true,
        default() {
          return 'Lookup';
        }
      },
      uniqueIdentifier: {
        type: String,
        default: 'code'
      },
      columns: {
        type: Array,
        default() {
          return [
            {
              text: 'Code',
              datafield: 'code'
            },
            {
              text: 'Description',
              datafield: 'description'
            }
          ];
        }
      },
      datafields: {
        type: Array,
        default() {
          return [
            {
              name: 'code',
              type: 'string'
            },
            {
              name: 'description',
              type: 'string'
            }
          ];
        }
      },
      codeTypes: {
        type: Array
      },
      initialSort: {
        type: Object,
        default() {
          return {
            column: 'description',
            direction: 'asc'
          };
        }
      },
      groupable: {
        type: Boolean,
        default: true
      },
      maximumSelectable: {
        type: Number,
        default: -1
      },
      showSelectAllButtons: {
        type: Boolean,
        default: true
      },
      value: {
        type: [Array, Object]
      }
    },
    data() {
      return {
        sortableSelect: true,
        sortableSearch: true,
        // eslint-disable-next-line no-undef, new-cap
        dataAdapterSearch: new jqx.dataAdapter(this.sourceSearch),
        // eslint-disable-next-line no-undef, new-cap
        dataAdapterSelect: new jqx.dataAdapter(this.sourceSelect),
        inputVal: '',
        currentSort: {
          search: this.initialSort,
          select: this.initialSort
        },
        rowsSelected: {},
        selectedCodes: [],
        apiPath: null,
        preventDialog: true,
        maximumSelectionLimitReached: false,
        selectedRowCount: 0
      };
    },
    methods: {
      // method for handling sorting in search table
      customSearchSort(e) {
        let column = e.args.sortinformation.sortcolumn;
        let direction = e.args.sortinformation.sortdirection.ascending;
        let sortdata = this.$refs[this.refSearch].getrows();
        if (direction != null) {
          sortdata.sort((a, b) => this.compare(a, b, column, direction));
        }
        this.sourceSearch.localData = sortdata;
        // assign sorted data to grid
        this.clearTable(this.sourceSearch, this.$refs[this.refSearch]);
        this.$refs[this.refSearch].addrow(null, sortdata);
      },

      // method for handling sorting in search table
      customSelectSort(e) {
        let column = e.args.sortinformation.sortcolumn;
        let direction = e.args.sortinformation.sortdirection.ascending;

        let sortdata = this.$refs[this.refSelect].getrows();
        if (direction != null) {
          sortdata.sort((a, b) => this.compare(a, b, column, direction));
        }
        this.sourceSelect.localData = sortdata;
        // assign sorted data to grid
        this.clearTable(this.sourceSelect, this.$refs[this.refSelect]);
        this.$refs[this.refSelect].addrow(null, sortdata);
        this.selectedRowCount = this.$refs[this.refSelect].getrows().length;
      },

      compare(v1, v2, column, direction) {
        let value1 = v1[column];
        let value2 = v2[column];

        try {
          let tempValue1 = parseInt(value1);
          let tempValue2 = parseInt(value2);
          if (isNaN(tempValue1) && direction) {
            if (value1 < value2) {
              return -1;
            }
            if (value1 > value2) {
              return 1;
            }
          } else if (isNaN(tempValue1) && !direction) {
            if (value1 < value2) {
              return 1;
            }
            if (value1 > value2) {
              return -1;
            }
          } else if (!isNaN(tempValue1) && direction) {
            if (tempValue1 < tempValue2) {
              return -1;
            }
            if (tempValue1 > tempValue2) {
              return 1;
            }
          } else if (!isNaN(tempValue1) && !direction) {
            if (tempValue1 < tempValue2) {
              return 1;
            }
            if (tempValue1 > tempValue2) {
              return -1;
            }
          }
        } catch (error) {
          console.log(error);
        }

        return 0;
      },
      searchTableReady() {
        this.$refs[this.refSearch].sortby(
          this.currentSort.search.column,
          this.currentSort.search.direction
        );
      },
      selectTableReady() {
        // First thing, let's tell the parent we're ready to rock
        this.$emit('table-ready');

        if (!this.rowsSelected.init) this.rowsSelected.init = [];

        if(this.$route.params.selectedRows !== undefined) {
          this.lookupVal.inputVal = JSON.parse(this.$route.params.selectedRows);
        }

        this.$refs[this.refSelect].addrow(null, this.lookupVal.inputVal);

        this.$refs[this.refSelect].sortby(
          this.currentSort.select.column,
          this.currentSort.select.direction
        );

        for (let i in this.lookupVal.inputVal) {
          // Need to save
          this.rowsSelected.init.push(
            this.lookupVal.inputVal[i][this.uniqueIdentifier]
          );
        }

        this.isMaxSelectedReached();

        this.selectedRowCount = this.$refs[this.refSelect].getrows().length;
      },
      clearTable(s, t) {
        t.clear();
        this.selectedRowCount = this.$refs[this.refSelect].getrows().length;
      },
      onSearch(v) {
        this.search(v, this);
      },
      search: debounce((search, _this) => {
        if (search.toString().length < 3) {
          _this.clearTable(_this.sourceSearch, _this.$refs[_this.refSearch]);
          _this.$refs.miamiLoader.closeLoader();
          return;
        }
        _this.$refs.miamiLoader.openLoader();

        _this
          .$http({
            url: '/data-service/' + _this.apiPath + '/',
            params: {
              searchString: search,
              limit: 30000
            }
          })
          .then((res) => {
            _this.clearTable(_this.sourceSearch, _this.$refs[_this.refSearch]);

            // We have to remove anything that is currently selected
            let selectedRows = _this.$refs[_this.refSelect].getdisplayrows();

            const newData = res.data.filter(function (i) {
              return !selectedRows.find(function (j) {
                return j[_this.uniqueIdentifier] == i[_this.uniqueIdentifier];
              });
            });
            _this.$refs[_this.refSearch].addrow(null, newData);

            // Initial sort
            _this.$refs[_this.refSearch].sortby(
              _this.currentSort.search.column,
              _this.currentSort.search.direction
            );

            _this.$refs.miamiLoader.closeLoader();

            // Preparation for saving later
            if (!_this.rowsSelected[_this.inputVal]) {
              _this.rowsSelected[_this.inputVal] = [];
            }
          });
      }, 800),
      onSearchRowSelect(e) {
        // If we've reached the maximum selections allowed, clear selections and return
        var v = this;

        if (this.maximumSelectionLimitReached) {
          setTimeout(function () {
            v.$refs[v.refSearch].clearselection();
            v.$refs[v.refSelect].clearselection();
          }, 10);

          return;
        }

        this.$refs.miamiLoader.openLoader();

        // Before we do anything, get the boundindex of a few rows after the one we're about to delete...
        let displayedrows = this.$refs[this.refSearch].getdisplayrows();
        let indexOfBoundaryRow = 0;

        for (let i = 0; i < displayedrows.length; i++) {
          if (
            displayedrows[i][this.uniqueIdentifier] ==
            e.args.row[this.uniqueIdentifier]
          ) {
            indexOfBoundaryRow = i + 3;
            break;
          }
        }

        this.rowSelectHelper(
          this.sourceSearch,
          this.$refs[this.refSearch],
          this.sourceSelect,
          this.$refs[this.refSelect],
          e.args
        );

        setTimeout(function () {
          v.$refs[v.refSearch].clearselection();
          v.$refs[v.refSelect].clearselection();

          // Scroll back to where we were
          v.$refs[v.refSearch].ensurerowvisible(indexOfBoundaryRow);
        }, 10);

        // Resort the tables after adding new rows
        this.$refs[this.refSelect].sortby(
          this.currentSort.select.column,
          this.currentSort.select.direction
        );

        this.$refs[this.refSearch].sortby(
          this.currentSort.search.column,
          this.currentSort.search.direction
        );

        // Have to save what we've removed, initialize search array with empty array  to avoid undefined error
        if (!this.rowsSelected[this.inputVal]) {
          this.rowsSelected[this.inputVal] = [];
        }
        this.rowsSelected[this.inputVal].push(
          e.args.row[this.uniqueIdentifier]
        );
        this.selectedCodes = this.sourceSelect.localdata;

        // Emit the new filter value
        this.SaveFilter();

        // Check if we've now reached the maximum number of allowed selections
        this.isMaxSelectedReached();

        this.$refs.miamiLoader.closeLoader();
      },
      onSelectedRowSelect(e) {
        this.$refs.miamiLoader.openLoader();

        // Before we do anything, get the boundindex of a few rows after the one we're about to delete...
        let displayedrows = this.$refs[this.refSelect].getdisplayrows();
        let indexOfBoundaryRow = 0;

        for (let i = 0; i < displayedrows.length; i++) {
          if (
            displayedrows[i][this.uniqueIdentifier] ==
            e.args.row[this.uniqueIdentifier]
          ) {
            indexOfBoundaryRow = i + 3;
            break;
          }
        }

        // Only return the item to the search if it belongs there
        const itemSelected = e.args.row[this.uniqueIdentifier];

        // If the item should be in the search results, remove from select and add to search
        this.rowSelectHelper(
          this.sourceSelect,
          this.$refs[this.refSelect],
          this.sourceSearch,
          this.$refs[this.refSearch],
          e.args
        );
        // remove selected item from rowselected array except init array
        for (let s in this.rowsSelected) {
          if (!(s === 'init')) {
            this.rowsSelected[s] = this.rowsSelected[s].filter(
              (a) => a !== itemSelected
            );
          }
        }
        var v = this;
        setTimeout(function () {
          v.$refs[v.refSearch].clearselection();
          v.$refs[v.refSelect].clearselection();

          // Scroll back to where we were
          v.$refs[v.refSelect].ensurerowvisible(indexOfBoundaryRow);
        }, 10);

        // Emit the new filter value
        this.SaveFilter();

        // Check if we've now reached the maximum number of allowed selections
        this.isMaxSelectedReached();

        this.$refs.miamiLoader.closeLoader();
      },
      rowSelectHelper(fromSource, fromTable, toSource, toTable, selection) {
        this.rowRemoveHelper(fromSource, fromTable, selection);
        this.rowAddHelper(toSource, toTable, selection);
        this.selectedRowCount = this.$refs[this.refSelect].getrows().length;
      },
      rowRemoveHelper(fromSource, fromTable, selection) {
        fromTable.deleterow(selection.row.uid);
      },
      rowAddHelper(toSource, toTable, selection) {
        let rowToAdd = {};
        for (let i in this.columns) {
          if (selection.row[this.columns[i].datafield]) {
            rowToAdd[this.columns[i].datafield] =
              selection.row[this.columns[i].datafield];
          }
        }

        toTable.addrow(selection.row[this.uniqueIdentifier], rowToAdd);
      },
      onSearchSort(e) {
        this.currentSort.search = {
          column: e.args.sortinformation.sortcolumn,
          direction: e.args.sortinformation.sortdirection.ascending
            ? 'asc'
            : 'desc'
        };
      },
      onSelectSort(e) {
        this.currentSort.select = {
          column: e.args.sortinformation.sortcolumn,
          direction: e.args.sortinformation.sortdirection.ascending
            ? 'asc'
            : 'desc'
        };
      },
      onSelectAllSearch() {
        this.$refs.miamiLoader.openLoader();
        var _this = this;

        // Tiny delay to allow the loader to display
        setTimeout(function () {
          let filterInfo = _this.$refs[_this.refSearch].getfilterinformation();
          let allFilteredRows = _this.$refs[_this.refSearch].getdisplayrows();

          // Nothing is showing, do nothing
          if (allFilteredRows.length == 0) {
            _this.$refs.miamiLoader.closeLoader();
            return;
          }
          // initialize search array with empty array to avvoid undefined error
          for (let i in allFilteredRows) {
            if (!_this.rowsSelected[_this.inputVal]) {
              _this.rowsSelected[_this.inputVal] = [];
            }
            _this.rowsSelected[_this.inputVal].push(
              allFilteredRows[i][_this.uniqueIdentifier]
            );
          }

          _this.$refs[_this.refSelect].addrow(null, allFilteredRows);

          // No filters on - use the faster version
          if (!filterInfo || filterInfo.length == 0) {
            _this.$refs[_this.refSearch].clear();
          } else {
            // Ths only way I can come up with to make this work for now...
            // but please let's find time to do this better
            // removerow takes rowid FROM BEFORE FILTERING
            // but getdisplayrows gives us rowid AFTER FILTERING
            let allRows = _this.$refs[_this.refSearch].getrows();

            // Create our own unique list
            let uniqueList = [];
            for (let i in allFilteredRows) {
              uniqueList.push(allFilteredRows[i][this.uniqueIdentifier]);
            }

            // Now find the row ids
            let rowIDs = [];
            for (let i in allRows) {
              if (uniqueList.includes(allRows[i][this.uniqueIdentifier])) {
                rowIDs.push(allRows[i].uid);
              }
            }

            _this.$refs[_this.refSearch].deleterow(rowIDs);
          }

          _this.$refs[_this.refSelect].sortby(
            _this.currentSort.select.column,
            _this.currentSort.select.direction
          );

          _this.SaveFilter();

          _this.$refs.miamiLoader.closeLoader();
          _this.selectedRowCount =
            _this.$refs[_this.refSelect].getrows().length;
        }, 10);
      },
      onSelectNoneSelect() {
        this.$refs.miamiLoader.openLoader();
        var _this = this;

        // Tiny delay to allow the loader to display
        setTimeout(function () {
          let filterInfo = _this.$refs[_this.refSelect].getfilterinformation();
          let allFilteredRows = _this.$refs[_this.refSelect].getdisplayrows();

          // Nothing is showing, do nothing
          if (allFilteredRows.length == 0) {
            _this.$refs.miamiLoader.closeLoader();
            return;
          }

          // No filters on - use the faster version
          if (!filterInfo || filterInfo.length == 0) {
            _this.$refs[_this.refSelect].clear();
            _this.rowsSelected = {};
          } else {
            // Create our own unique list
            let uniqueList = [];

            for (let i in allFilteredRows) {
              // Used for the deselect later
              uniqueList.push(allFilteredRows[i][this.uniqueIdentifier]);

              for (let j in _this.rowsSelected) {
                let iToRemove = _this.rowsSelected[j].indexOf(
                  allFilteredRows[i][_this.uniqueIdentifier]
                );

                if (iToRemove != -1) {
                  _this.rowsSelected[j].splice(iToRemove, 1);
                }
              }
            }

            let allRows = _this.$refs[_this.refSelect].getrows();

            // Now find the row ids
            let rowIDs = [];
            for (let i in allRows) {
              if (uniqueList.includes(allRows[i][this.uniqueIdentifier])) {
                rowIDs.push(allRows[i].uid);
              }
            }

            _this.$refs[_this.refSelect].deleterow(rowIDs);
          }

          _this.SaveFilter();
          let searchedRows = _this.$refs[_this.refSearch].getdisplayrows();
          let mergedRows = [];
          if (searchedRows.length === 0) {
            _this.$refs[_this.refSearch].addrow(null, allFilteredRows);
          } else {
            mergedRows = [...searchedRows, ...allFilteredRows];
            _this.$refs[_this.refSearch].clear();
            _this.$refs[_this.refSearch].addrow(null, mergedRows);
          }

          _this.$refs.miamiLoader.closeLoader();
          _this.selectedRowCount =
            _this.$refs[_this.refSelect].getrows().length;
        }, 10);
      },
      SaveFilter() {
        // SAVE YOUR FILTER DATA HERE
        const savedRows = this.$refs[this.refSelect].getrows();
        let emitValue = savedRows;

        this.$emit('input', { inputVal: emitValue, apiPath: this.apiPath });
      },
      showToggleConfirmation(ov) {
        this.$snotify.confirm(
          'Switching code types will clear all selections because code types cannot be mixed.',
          'Are you sure?',
          {
            position: SnotifyPosition.centerCenter,
            buttons: [
              {
                text: 'Proceed',
                action: (toast) => {
                  this.clearTable(
                    this.sourceSearch,
                    this.$refs[this.refSearch]
                  );
                  this.clearTable(
                    this.sourceSelect,
                    this.$refs[this.refSelect]
                  );

                  this.rowsSelected = [];

                  if (this.inputVal !== '' || this.inputVal !== null) {
                    this.search(this.inputVal, this);
                  }
                  this.$snotify.remove(toast.id);
                }
              },
              {
                text: 'Cancel',
                action: (toast) => {
                  this.preventDialog = true;
                  this.apiPath = ov;
                  this.$snotify.remove(toast.id);
                }
              }
            ],
            backdrop: 0.5
          }
        );
      },
      isMaxSelectedReached() {
        if (this.maximumSelectable == -1) {
          this.maximumSelectionLimitReached = false;
          return;
        }

        this.maximumSelectionLimitReached =
          this.maximumSelectable <= this.$refs[this.refSelect].getrows().length;
      }
    },
    computed: {
      refSearch() {
        return this.searchId + 'SearchGrid';
      },
      refSelect() {
        return this.searchId + 'SelectGrid';
      },
      multiType() {
        return this.codeTypes.length > 1;
      },
      lookupVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', { inputVal: this.val, apiPath: this.apiPath });
        }
      }
    },
    watch: {
      apiPath(nv, ov) {
        if (this.preventDialog) {
          this.preventDialog = false;
        } else {
          this.showToggleConfirmation(ov);
        }
      }
    },
    beforeCreate() {
      this.sourceSearch = {
        datatype: 'json',
        datafields: [
          // default
          {
            name: 'code',
            type: 'string'
          },
          {
            name: 'ndc',
            type: 'string'
          },
          {
            name: 'description',
            type: 'string'
          },
          {
            name: 'drugGpi',
            type: 'string'
          },
          {
            name: 'drugGpiName',
            type: 'string'
          },
          {
            name: 'drugUsc',
            type: 'string'
          },
          {
            name: 'drugUscDescription',
            type: 'string'
          },
          {
            name: 'ndcDescription',
            type: 'string'
          },
          {
            name: 'drugAhfsClass',
            type: 'string'
          },
          {
            name: 'drugAhfsClassDescription',
            type: 'string'
          },
          {
            name: 'drugMfrName',
            type: 'string'
          }
        ],
        localData: []
      };

      this.sourceSelect = {
        datatype: 'json',
        datafields: [
          // default
          {
            name: 'code',
            type: 'string'
          },
          {
            name: 'description',
            type: 'string'
          },
          {
            name: 'drugGpi',
            type: 'string'
          },
          {
            name: 'drugGpiName',
            type: 'string'
          },
          {
            name: 'drugUsc',
            type: 'string'
          },
          {
            name: 'drugUscDescription',
            type: 'string'
          },
          {
            name: 'ndcDescription',
            type: 'string'
          },
          {
            name: 'drugAhfsClass',
            type: 'string'
          },
          {
            name: 'drugAhfsClassDescription',
            type: 'string'
          },
          {
            name: 'drugMfrName',
            type: 'string'
          }
        ],
        localData: []
      };
    },
    mounted() {
      this.apiPath = this.value.apiPath ? this.value.apiPath : 'ndcsv2';
    }
  };
</script>
