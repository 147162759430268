<template>
  <date-range v-model="dateRange" class="rfs-m-8-b" v-bind="$attrs" />
</template>

<script>
  import selectStore from '../../../store/modules/global/filters/select';
  import dateRange from './../../base/forms/date_range';
  import { defaultDateRange } from '@/data';

  export default {
    name: 'DateRangeMagic',
    components: {
      dateRange
    },
    props: {
      moduleNamespace: {
        type: String,
        required: true
      },
      value: {
        type: Object,
        default() {
          return defaultDateRange;
        }
      }
    },
    computed: {
      dateRange: {
        get() {
          return this.$store.getters[this.moduleNamespace + '/getValue'];
        },
        set(value) {
          return this.$store.commit(this.moduleNamespace + '/setValue', value);
        }
      }
    },
    methods: {},
    beforeCreate() {
    },
    created() {
      this.magicModule(this.moduleNamespace, selectStore);
      this.$store.commit(this.moduleNamespace + '/setValue', this.value);
    },
    beforeDestroy() {
      // Unregister the dynamically created module
      if (this.$store.hasModule(this.moduleNamespace)) {
        this.$store.unregisterModule(this.moduleNamespace);
      }
    }
  };
</script>
