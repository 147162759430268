<template>
  <fieldset
    style="position: relative"
    data-test="add-medication-group-fieldset"
  >
    <span
      data-test-remove
      title="remove"
      class="fs-20 fw-700"
      style="position: absolute; right: 8px; top: 0; cursor: pointer"
      v-on:click="$emit('delete'); onDelete()"
      >x</span
    >
    <legend>Medication Group</legend>
    <single-select
      class="lg:col-span-3 flex-1 rfs-m-4-b"
      v-model="operatorVal"
      :options="operatorOptions"
      :clearable="false"
      required
      @input="onOperatorInput"
    />
    <multi-select
      v-model="inputVal"
      v-bind="$attrs"
      :options="options"
      optionLabel="description"
      optionValue="code"
      placeholder="Type to Search"
      @input="onInput"
    >
      <template #selected-option-container="{ option, deselect }">
        <div
          style="width: 100%"
          class="rfs-m-2-t rfs-m-2-b flex items-center content-center"
        >
          <span>{{ option.description }}</span>
          <button
            type="button"
            title="Clear Selected"
            aria-label="Clear Selected"
            class="vs__clear order-first"
            @click="deselect(option); checkListContents();"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
              <path
                d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
              ></path>
            </svg>
          </button>
        </div>
        <div style="width: 100%" v-if="inputVal.length">{{ joiningBy }}</div>
      </template>
      <template #no-options="{}">
        You have no Medication Groups. <br /><router-link
          to="medication-group/manage/create"
          >Make some
        </router-link>
        or add drugs by NDC.
      </template>
    </multi-select>
  </fieldset>
</template>

<script>
  import selectStore from '../../../store/modules/global/filters/select';
  import MultiSelect from './../../base/forms/multi_select';
  import SingleSelect from './../../base/forms/single_select';

  import debounce from 'lodash/debounce';
  import * as events from "events";
  import {eventsHub} from "@/main";

  export default {
    name: 'MedicationGroupSelectMagic',
    components: {
      MultiSelect,
      SingleSelect
    },
    props: {
      filterKey: Number,
      moduleNamespace: {
        type: String,
        required: true
      },
      value: [Object, Array],
      logicalOperator: {
        type: String,
        default: 'or'
      },
      operator: {
        type: String,
        default: 'includes'
      }
    },
    data() {
      return {
        operatorOptions: ['includes', 'excludes'],
        operatorVal: this.operator
      };
    },
    computed: {
      inputVal: {
        get() {
          return this.$store.getters[this.moduleNamespace + '/getValue'];
        },
        set(value) {
          return this.$store.commit(this.moduleNamespace + '/setValue', value);
        }
      },
      isListEmpty() {
        return this.inputVal.length === 0;
      },
      options() {
        const savedList =
          this.$store.getters['medicationGroupsManagement/getSavedList'];
        return savedList.map((obj) => {
          let rObj = {};
          rObj.code = obj.id.toString();
          rObj.description = obj.name;
          return rObj;
        });
      },
      joiningBy() {
        return this.logicalOperator;
      }
    },
    methods: {
      onInput(v) {
        this.update(this);
        eventsHub.$emit('medicationList', v);
      },
      onOperatorInput() {
        this.$emit('operator', this.operatorVal, this.filterKey);
        if (this.inputVal && this.inputVal.length) {
          this.$emit('update-population');
        }
      },
      checkListContents() {
        if (this.isListEmpty) {
          this.$emit('delete');
          this.onDelete();
        }
      },
      onDelete() {
        eventsHub.$emit('deleteMedicationGroup');
      },
      update: debounce((_this) => {
        _this.$emit('update-population');
      }, 300)
    },
    created() {
      this.magicModule(this.moduleNamespace, selectStore);
      this.$store.commit(this.moduleNamespace + '/setValue', this.value);
      this.$store.dispatch('medicationGroupsManagement/getSavedList');
    }
  };
</script>
