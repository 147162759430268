// Filters
import DateRangeMagic from '../../components/filters/magicstore/DateRangeMagic';
import CheckboxMagic from '../../components/filters/magicstore/CheckboxMagic';
import DiagnosisSelectMagic from '../../components/filters/magicstore/DiagnosisSelectMagic';
import TherapeuticClassSelectMagic from '../../components/filters/magicstore/TherapeuticClassSelectMagic';
import NdcSelectMagic from '../../components/filters/magicstore/NdcSelectMagic';
import MedicationGroupSelectMagic from '../../components/filters/magicstore/MedicationGroupSelectMagic';
import ProceduresSelectMagic from '../../components/filters/magicstore/ProceduresSelectMagic';
import DiagnosisGroupSelectMagic from '../../components/filters/magicstore/DiagnosisGroupSelectMagic';

// Base Form Controls
import TextInput from '../../components/base/forms/text_input';
import SingleSelect from '../../components/base/forms/single_select.vue';

// Lodash helpers;
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

export default {
  components: {
    DateRangeMagic,
    CheckboxMagic,
    DiagnosisSelectMagic,
    TherapeuticClassSelectMagic,
    NdcSelectMagic,
    TextInput,
    MedicationGroupSelectMagic,
    SingleSelect,
    ProceduresSelectMagic,
    DiagnosisGroupSelectMagic
  },
  inject: ['populationStore', 'populationWorkflowStore'],
  data() {
    return {
      modName: 'pop/unsaved_population', // default for unsaved pop - change for update template
      filters: [],
      populationCriteriaLocal: [],
      trackLocal: 0,
      filterTypeCounts: {},
      populationNameLocal: null,
      populationId: null,
      addFilterValue: ''
    };
  },
  methods: {
    setSavedFlag() {
      // I don't think this does anything;
      this.populationStore.recalculateSavedFlag();
    },
    saveQueryFilters({ isNew = false, isRename = false }) {
      let rulesToUse = [];

      if (isRename) {
        rulesToUse = rulesToUse.concat(
          this.populationWorkflowStore.getInitialRules()
        );
      } else {
        rulesToUse = rulesToUse.concat(this.populationCriteriaLocal);
      }

      let data = {
        // change my values to make fun new populations
        populationName: this.populationNameLocal,
        logicalOperator: 'and',
        rules: rulesToUse
      };
      const id = !isNew ? this.populationStore.getSavedId() : null;

      // need new save endpoint stuff
      this.$store
        .dispatch('populationManagement/savePopulation', { data: data, id: id })
        .then((resp) => {
          if (resp.success) {
            // no mater what, let ppl know if saving was successful
            this.$snotify.success('Characteristics saved!');
          }
          /*
            There are two ways we could get here -
              1 - We saved a population with a save button
              2 - We tried to change which population is open and saved along the way

            What happens after saving depends on which path we're on
              1 - If a new population was created, open it. If not, show a confirmation
              2 - Switch to the new population being opened
          */

          // Save with the save buttons on the page
          if (!this.switchingPopulationTo) {
            // This means that a new population was saved
            if (resp.data) {
              this.$router.push({
                name: 'PopulationBuilder',
                params: { popid: resp.data.id }
              });
            }

            // If we get here, then it's not a new population
            if (resp.success) {
              // If this isn't a rename, then we need to update the "initial rules"
              //    to the rules we just saved
              if (!isNew && !isRename) {
                this.populationWorkflowStore.saveInitialRules(rulesToUse);
              }

              this.saveSuccess();
            }
          }
          // Saved while opening a different population
          else {
            this.changePopulation();
          }
        });
    },
    removeFilter(filterKey, update) {
      const filter = find(this.filters, { localKey: filterKey });
      filter.unwatch();
      const name = filter.name;
      const filterIndex = findIndex(this.filters, { localKey: filterKey });
      this.filters.splice(filterIndex, 1);
      const pclIndex = findIndex(this.populationCriteriaLocal, {
        localKey: filterKey
      });
      this.populationCriteriaLocal.splice(pclIndex, 1);
      this.filterTypeCounts[name] = this.filterTypeCounts[name] - 1;

      if (update) {
        this.updatePopulation('removeFilter'); // don't think we need that argument in the new order of things
      }

      this.setSavedFlag();
    },
    updateOperator(value, filterKey) {
      const pclIndex = findIndex(this.populationCriteriaLocal, {
        localKey: filterKey
      });
      this.populationCriteriaLocal[pclIndex].operator = value;
      this.setSavedFlag();
    },
    alert(message) {
      this.$snotify.error(message);
    },
    unwatchFilters() {},
    resetToSaved() {
    },
    resetCriteria() {
      for (let i = this.filters.length - 1; i >= 0; i--) {
        this.removeFilter(this.filters[i].localKey);
      }
    },
    resetCriteriaPartially() {
      for (let i = this.filters.length - 1; i >= 0; i--) {
        if(this.filters[i].name !== 'drugGroups' && this.filters[i].name !== 'ndc') {
          this.removeFilter(this.filters[i].localKey);
        }
      }
    },
    loadCriteria(criteria) {
      const saved =
        JSON.stringify(criteria) ===
        JSON.stringify(this.populationCriteriaLocal);

      criteria.forEach((filter, i) => {
        const filterKey = (this.trackLocal = this.trackLocal + 1);
        switch (filter.name) {
          case 'principalDiagnosisCcs':
          case 'principalDiagnosis':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'principalDiagnosis'
              ) &&
              this.filterTypeCounts.principalDiagnosis > 0
            ) {
              this.alert('Principal Diagnosis is only allowed once!');
              return;
            }
            this.filterTypeCounts.principalDiagnosis = 1;
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'diagnosis-select-magic',
              name: 'principalDiagnosis',
              props: {
                moduleNamespace: this.modName + '/filters/principalDiagnosis',
                modalName: 'pd' + filterKey,
                value: {
                  inputVal: filter.valueCodes ? filter.valueCodes : [],
                  apiPath: 'ccss'
                },
                fieldLabel: 'Principal Diagnosis',
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                let valueCodes = [];
                if (
                  newValue.inputVal !== undefined &&
                  newValue.inputVal !== null
                ) {
                  valueCodes = newValue.inputVal.map((obj) => {
                    let rObj = {};
                    rObj.code = obj.code;
                    rObj.description = obj.description;
                    return rObj;
                  });
                }
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.name =
                  newValue.apiPath === 'icd10s'
                    ? 'principalDiagnosisIcd10'
                    : 'principalDiagnosisCcs';
                filter.valueCodes = valueCodes;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'principalDiagnosisCcs',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }

            break;
          case 'principalDiagnosisIcd10':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'principalDiagnosis'
              ) &&
              this.filterTypeCounts.principalDiagnosis > 0
            ) {
              this.alert('Principal Diagnosis is only allowed once!');
              return;
            }
            this.filterTypeCounts.principalDiagnosis = 1;
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'diagnosis-select-magic',
              name: 'principalDiagnosis',
              props: {
                moduleNamespace: this.modName + '/filters/principalDiagnosis', // need to emit code type too
                modalName: 'pd' + filterKey,
                value: {
                  inputVal: filter.valueCodes ? filter.valueCodes : [],
                  apiPath: 'icd10s'
                },
                fieldLabel: 'Principal Diagnosis',
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                let valueCodes = [];
                if (
                  newValue.inputVal !== undefined &&
                  newValue.inputVal !== null
                ) {
                  valueCodes = newValue.inputVal.map((obj) => {
                    let rObj = {};
                    rObj.code = obj.code;
                    rObj.description = obj.description;
                    return rObj;
                  });
                }
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.name =
                  newValue.apiPath === 'icd10s'
                    ? 'principalDiagnosisIcd10'
                    : 'principalDiagnosisCcs';
                filter.valueCodes = valueCodes;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'principalDiagnosisIcd10',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }
            break;
          case 'comorbidityIcd10':
          case 'comorbidity':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'comorbidityIcd10'
              ) &&
              this.filterTypeCounts.comorbidity > 0
            ) {
              this.filterTypeCounts.comorbidity++;
            } else {
              this.filterTypeCounts.comorbidity = 1;
            }
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'diagnosis-select-magic',
              name: 'comorbidity',
              props: {
                moduleNamespace:
                  this.modName + '/filters/comorbidity/' + filterKey,
                modalName: 'icd10' + filterKey,
                value: {
                  inputVal: filter.valueCodes ? filter.valueCodes : [],
                  apiPath: 'icd10s'
                },
                codeTypes: [
                  {
                    id: 'icd10s',
                    apiPath: 'icd10s',
                    name: 'ICD-10'
                  }
                ],
                fieldLabel: 'Comorbidity (ICD-10)',
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                let valueCodes = [];
                if (
                  newValue.inputVal !== undefined &&
                  newValue.inputVal !== null
                ) {
                  valueCodes = newValue.inputVal.map((obj) => {
                    let rObj = {};
                    rObj.code = obj.code;
                    rObj.description = obj.description;
                    return rObj;
                  });
                }
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.valueCodes = valueCodes;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'comorbidityIcd10',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }
            break;
          case 'therapeuticClassAhfs':
          case 'therapeuticClass':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'therapeuticClass'
              ) &&
              this.filterTypeCounts.therapeuticClass > 0
            ) {
              this.filterTypeCounts.therapeuticClass++;
            } else {
              this.filterTypeCounts.therapeuticClass = 1;
            }
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'therapeutic-class-select-magic',
              name: 'therapeuticClass',
              props: {
                moduleNamespace:
                  this.modName + '/filters/therapeuticClass/' + filterKey,
                modalName: 'ahfs' + filterKey,
                value: {
                  inputVal: filter.valueCodes ? filter.valueCodes : [],
                  apiPath: 'ahfs'
                },
                fieldLabel: 'Therapeutic Class',
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                let valueCodes = [];
                if (
                  newValue.inputVal !== undefined &&
                  newValue.inputVal !== null
                ) {
                  valueCodes = newValue.inputVal.map((obj) => {
                    let rObj = {};
                    rObj.code = obj.code;
                    rObj.description = obj.description;
                    return rObj;
                  });
                }
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.name =
                  newValue.apiPath === 'uscs'
                    ? 'therapeuticClassUsc'
                    : 'therapeuticClassAhfs';
                filter.valueCodes = valueCodes;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'therapeuticClassUsc',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }
            break;
          case 'therapeuticClassUsc':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'therapeuticClass'
              ) &&
              this.filterTypeCounts.therapeuticClass > 0
            ) {
              this.filterTypeCounts.therapeuticClass++;
            } else {
              this.filterTypeCounts.therapeuticClass = 1;
            }
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'therapeutic-class-select-magic',
              name: 'therapeuticClass',
              props: {
                moduleNamespace:
                  this.modName + '/filters/therapeuticClass/' + filterKey,
                modalName: 'usc' + filterKey,
                value: {
                  inputVal: filter.valueCodes ? filter.valueCodes : [],
                  apiPath: 'uscs'
                },
                fieldLabel: 'Therapeutic Class',
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                let valueCodes = [];
                if (
                  newValue.inputVal !== undefined &&
                  newValue.inputVal !== null
                ) {
                  valueCodes = newValue.inputVal.map((obj) => {
                    let rObj = {};
                    rObj.code = obj.code;
                    rObj.description = obj.description;
                    return rObj;
                  });
                }
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.name =
                  newValue.apiPath === 'uscs'
                    ? 'therapeuticClassUsc'
                    : 'therapeuticClassAhfs';
                filter.valueCodes = valueCodes;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'therapeuticClassUsc',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }
            break;
          case 'ndc':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'ndc'
              ) &&
              this.filterTypeCounts.ndc > 0
            ) {
              this.filterTypeCounts.ndc++;
            } else {
              this.filterTypeCounts.ndc = 1;
            }
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'ndc-select-magic',
              name: 'ndc',
              props: {
                moduleNamespace: this.modName + '/filters/ndc/' + filterKey,
                modalName: 'ndc' + filterKey,
                value: {
                  apiPath: 'ndcsv2',
                  inputVal: !filter.valueCodes
                    ? []
                    : filter.valueCodes
                      .filter(obj => obj.codeType === undefined || (obj.codeType !== undefined && obj.codeType !== 'GPI')).map((obj) => {
                        let rObj = {};
                        rObj.ndc = obj.code;
                        rObj.ndcDescription = obj.description;
                        return rObj;
                      })
                },
                codeTypes: [
                  {
                    id: 'ndcsv2',
                    apiPath: 'ndcsv2',
                    name: 'NDC'
                  }
                ],
                fieldLabel: 'Medications By NDC',
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                let valueCodes = [];
                if (
                  newValue.inputVal !== undefined &&
                  newValue.inputVal !== null
                ) {
                  valueCodes = newValue.inputVal.map((obj) => {
                    let rObj = {};
                    rObj.code = obj.ndc;
                    rObj.description = obj.ndcDescription;
                    return rObj;
                  });
                const  gpiValueCodes = newValue.inputVal.map((obj) => {
                  if(obj.drugGpi !== undefined && obj.drugGpi.trim() !== ''){
                    let rObj = {};
                    rObj.codeType = 'GPI';
                    rObj.code = obj.drugGpi;
                    rObj.description = obj.drugGpiName;
                    return rObj;
                  }
                  });

                  gpiValueCodes.forEach(obj => {
                    if(obj !== undefined) {
                      valueCodes.push(obj);
                    }
                  })
                }
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.valueCodes = valueCodes;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'ndc',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }
            break;
          case 'drugGroups':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'drugGroups'
              ) &&
              this.filterTypeCounts.drugGroups > 0
            ) {
              this.filterTypeCounts.drugGroups++;
            } else {
              this.filterTypeCounts.drugGroups = 1;
            }
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'medication-group-select-magic',
              name: 'drugGroups',
              props: {
                moduleNamespace:
                  this.modName + '/filters/drugGroups/' + filterKey,
                value: filter.valueCodes ? filter.valueCodes : [],
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.valueCodes = newValue;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'drugGroups',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }
            break;
          case 'procedureCodes':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'procedureCodes'
              ) &&
              this.filterTypeCounts.procedureCodes > 0
            ) {
              this.filterTypeCounts.procedureCodes++;
            } else {
              this.filterTypeCounts.procedureCodes = 1;
            }
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'procedures-select-magic',
              name: 'procedureCodes',
              props: {
                moduleNamespace:
                  this.modName + '/filters/procedureCodes/' + filterKey,
                modalName: 'pc' + filterKey,
                value: {
                  apiPath: 'procedures-code',
                  inputVal: !filter.valueCodes
                    ? []
                    : filter.valueCodes.map((obj) => {
                        let rObj = {};
                        rObj.code = obj.code;
                        rObj.description = obj.description;
                        rObj.field2 = obj.codeType;
                        return rObj;
                      })
                },
                codeTypes: [
                  {
                    id: 'procedures-code',
                    apiPath: 'procedures-code',
                    name: 'procedureCodes'
                  }
                ],
                fieldLabel: 'Procedures',
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                let valueCodes = [];
                if (
                  newValue.inputVal !== undefined &&
                  newValue.inputVal !== null
                ) {
                  valueCodes = newValue.inputVal.map((obj) => {
                    let rObj = {};
                    rObj.code = obj.code;
                    rObj.description = obj.description;
                    rObj.codeType = obj.field2;
                    return rObj;
                  });
                }
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.valueCodes = valueCodes;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'procedureCodes',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }
            break;
          case 'diagnosisGroup':
            if (
              Object.prototype.hasOwnProperty.call(
                this.filterTypeCounts,
                'diagnosisGroup'
              ) &&
              this.filterTypeCounts.diagnosisGroup > 0
            ) {
              this.filterTypeCounts.diagnosisGroup++;
            } else {
              this.filterTypeCounts.diagnosisGroup = 1;
            }
            if (saved) {
              this.populationCriteriaLocal[i].localKey = filterKey;
            }
            this.filters.push({
              localKey: filterKey,
              fieldComponent: 'diagnosis-group-select-magic',
              name: 'diagnosisGroup',
              props: {
                moduleNamespace:
                  this.modName + '/filters/diagnosisGroup/' + filterKey,
                value: filter.valueCodes ? filter.valueCodes : [],
                operator: filter.operator
              },
              onchange(newValue, oldValue, _this) {
                const filter = find(_this.populationCriteriaLocal, {
                  localKey: filterKey
                });
                filter.valueCodes = newValue;
              }
            });
            if (!find(this.populationCriteriaLocal, { localKey: filterKey })) {
              this.populationCriteriaLocal.push({
                localKey: filterKey,
                name: 'diagnosisGroup',
                operator: 'includes',
                logicalOperator: 'or',
                valueCodes: []
              });
            }
            break;
          default:
            return;
        }

        const thisFilter = find(this.filters, { localKey: filterKey });
        thisFilter.unwatch = this.$store.watch(
          () =>
            this.$store.getters[thisFilter.props.moduleNamespace + '/getValue'],
          (newValue, oldValue) => {
            // do stuff on change of store value
            thisFilter.onchange(newValue, oldValue, this);
          },
          { deep: true }
        );
      });
    },
    addCriterion(criterion) {
      const filter = [
        {
          name: criterion
        }
      ];
      this.loadCriteria(filter);
      this.addFilterValue = '';
    }
  },
  computed: {
    populationCriteriaStore() {
      return this.populationWorkflowStore.getFilters();
    },
    saved() {
      return this.populationStore.isSaved();
    },
    populationNameCriteriaStore() {
      return this.populationStore.getName();
    }
  },
  updated() {},
  // add module destroy to components - seems like deletion could cause holes here
  // prob doesn't hurt to double check it here, since we check first if we still have it in the store
  beforeDestroy() {
    this.filters.forEach((filter) => {
      if (this.$store.hasModule(filter.props.moduleNamespace)) {
        this.$store.unregisterModule(filter.props.moduleNamespace);
      }
      filter.unwatch();
    });
  }
};
