<template>
  <fieldset style="position: relative"
            data-test="add-principle-diagnosis-fieldset">
    <span
      title="remove"
      class="fs-20 fw-700"
      style="position: absolute; right: 8px; top: 0; cursor: pointer"
      v-on:click="$emit('delete')"
      >x</span
    >
    <legend>{{ fieldLabel }}</legend>
    <div class="flex">
      <!-- prettier-ignore -->
      <single-select
        class="lg:col-span-3 flex-1"
        v-model="operatorVal"
        :options="operatorOptions"
        :clearable="false"
        required
        @input="$emit('operator', operatorVal, filterKey); $emit('update-population');"
      />
      <!-- prettier-ignore -->
      <button
        type="button"
        class="btn btn-primary flex-1 rfs-m-4-l"
        @click="showModal(); storeResetValue(); checkForChanges();"
      >
        <span v-if="!this.inputVal.inputVal">Select</span><span v-else>Edit</span> Diagnoses
      </button>
    </div>
    <portal to="modal">
      <modal
        data-test="add-diagnosis-modal"
        :name="modalName"
        width="100%"
        height="auto"
        :adaptive="true"
        classes="rfs-p-8"
        :clickToClose="false"
      >
        <h3 class="modal-title">
          <span v-if="!this.inputVal.inputVal">Select</span
          ><span v-else>Edit</span> Diagnoses
        </h3>
        <code-lookup
          v-bind="lookupProps"
          v-model.trim="inputVal"
          @input="checkForChanges()"
        >
        </code-lookup>
        <div v-if="mustCreateGroup" class="flex flex-row-reverse items-end">
          <p class="rfs-m-8-t rfs-m-4-r order-3">
            You must save selections greater than 9 as a Diagnosis Group.
          </p>
          <text-input
            data-test="group-name-input"
            name="groupName"
            id="groupName"
            class="order-2 rfs-m-4-r"
            v-model="groupName"
            fieldLabel="Group Name Required"
          />
          <button
            data-test="save-as-group"
            type="button"
            class="btn rfs-m-8-t order-1"
            @click="saveAndClose"
          >
            Save as Group
          </button>
        </div>
        <!-- prettier-ignore -->
        <button
          type="button"
          class="float-left btn rfs-m-8-t"
          @click="hideModal(); cancelSelection();"
        >
          Cancel
        </button>
        <!-- prettier-ignore -->
        <button
          v-if="!mustCreateGroup"
          :disabled="noChange"
          type="button"
          class="float-right btn rfs-m-8-t"
          @click="updateStatistics();"
        >
          Update Statistics
        </button>
      </modal>
    </portal>
    <ul class="rfs-m-4-t fs-14">
      <li v-for="(selected, i) in inputVal.inputVal" :key="i">
        {{ selected.code }}: {{ selected.description }}
        <div v-if="i != inputVal.inputVal.length - 1">{{ joiningBy }}</div>
      </li>
    </ul>
  </fieldset>
</template>

<script>
  import selectStore from '../../../store/modules/global/filters/select';
  import singleSelect from '../../../components/base/forms/single_select';
  import CodeLookup from '../CodeLookup';
  import isEqual from 'lodash/isEqual';
  import TextInput from '../../../components/base/forms/text_input';

  export default {
    name: 'DiagnosisSelectMagic',
    components: {
      CodeLookup,
      singleSelect,
      TextInput
    },
    props: {
      filterKey: Number,
      modalName: {
        type: String,
        default: 'diagnosis_code_modal_' + Math.floor(Math.random() * 1000 + 1)
      },
      value: {
        type: Object
      },
      moduleNamespace: {
        type: String,
        required: true
      },
      codeTypes: {
        type: Array,
        default() {
          return [
            {
              id: 'ccss',
              apiPath: 'ccss',
              name: 'CCS'
            },
            {
              id: 'icd10s',
              apiPath: 'icd10s',
              name: 'ICD-10'
            }
          ];
        }
      },
      fieldLabel: {
        type: String
      },
      operator: {
        type: String,
        default: 'includes'
      },
      logicalOperator: {
        type: String,
        default: 'or'
      }
    },
    data() {
      return {
        lookupProps: {
          searchId: 'searchCodeCcs',
          searchLabel: 'Diagnosis Lookup',
          codeTypes: this.codeTypes
        },
        operatorOptions: ['includes', 'excludes'],
        operatorVal: this.operator,
        noChange: true,
        groupName: '',
        selectedCount: 0
      };
    },
    computed: {
      inputVal: {
        get() {
          return this.$store.getters[this.moduleNamespace + '/getValue'];
        },
        set(value) {
          return this.$store.commit(this.moduleNamespace + '/setValue', value);
        }
      },
      joiningBy() {
        return this.logicalOperator;
      },
      mustCreateGroup() {
        return this.selectedCount > 9;
      }
    },
    methods: {
      storeResetValue() {
        this.resetValue =
          this.$store.getters[this.moduleNamespace + '/getValue'];
      },
      checkForChanges() {
        var selected = this.inputVal.inputVal.map(function (item) {
          return item.code;
        });
        var reset = this.resetValue.inputVal.map(function (item) {
          return item.code;
        });
        this.noChange = isEqual(selected, reset);
        // selected count
        this.selectedCount = selected.length;
      },
      cancelSelection() {
        this.inputVal = this.resetValue;
        if (
          !this.inputVal ||
          !this.inputVal.inputVal ||
          this.inputVal.inputVal.length === 0
        ) {
          this.$emit('empty-cancel');
        }
      },
      updateStatistics() {
        if (
          !this.inputVal ||
          !this.inputVal.inputVal ||
          this.inputVal.inputVal.length === 0
        ) {
          this.hideModal();
          this.$emit('delete');
        } else {
          this.hideModal();
          this.$emit('update-population');
        }
      },
      showModal() {
        const modalName = this.modalName;
        this.$modal.show(modalName);
      },
      hideModal() {
        const modalName = this.modalName;
        this.$modal.hide(modalName);
        this.$emit('scroll-check');
      },
      modalOnLoad() {},
      saveAndClose() {
        // if group we need to swap out filter to group
        if (this.groupName == '') {
          this.$snotify.error('Please name the group.', {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true
          });
          return;
        }
        // saving all as a group
        const dataObj = {
          name: this.groupName,
          diagnosisCodeType: this.inputVal.apiPath === 'ccss' ? 'CCS' : 'ICD10',
          diagnosisCodes: this.inputVal.inputVal.map(dig => dig.code)
        };
        this.$store
          .dispatch('diagnosisGroupsManagement/createOrUpdateDiagnosisGroup', dataObj)
          .then((resp) => {
            if (resp.success) {
              this.$snotify.success('Diagnosis group saved!');
              // cancelling PrincipalDiagnosis save & making newly created Diagnosis Group selected
              this.hideModal();
              // cancel original NDC box
              this.$emit('empty-cancel');
              this.$emit('swap-filter', [
                {
                  name: 'diagnosisGroup',
                  valueCodes: [
                    {
                      code: `${resp.data.id}`,
                      description: this.groupName
                    }
                  ]
                }
              ]);
            }
          });
      }
    },
    created() {
      this.magicModule(this.moduleNamespace, selectStore);
      this.$store.commit(this.moduleNamespace + '/setValue', this.value);
    },
    mounted() {
      if (
        !this.inputVal ||
        !this.inputVal.inputVal ||
        this.inputVal.inputVal.length === 0
      ) {
        // got to give the portal a spit second to load up
        var _this = this;
        setTimeout(function () {
          _this.storeResetValue();
          _this.showModal();
          _this.$watch('inputVal', (n) => {
            _this.checkForChanges();
          });
        }, 200);
      }
    }
  };
</script>
