<template>
  <div class="form-group">
    <input
      class="text-input"
      :class="{ 'has-value': inputVal }"
      :id="id"
      :type="$attrs.type ? $attrs.type : 'text'"
      v-model="inputVal"
      v-bind="$attrs"
      @change="$emit('change', $event)"
    />
    <label class="control-label" :for="id">{{ fieldLabel }}</label
    ><i class="bar"></i>
    <span class="field-note" v-if="fieldNote" v-html="fieldNote"></span>
  </div>
</template>

<script>
  export default {
    name: 'TextInput',
    props: {
      value: {
        type: String
      },
      fieldLabel: {
        type: String
      },
      fieldName: {
        type: String
      },
      id: {
        type: String,
        require: true,
        default() {
          return 'searchCode' + Math.floor(Math.random() * 1000 + 1);
        }
      },
      fieldNote: {
        type: String
      }
    },
    data() {
      return {};
    },
    methods: {},
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
  };
</script>
