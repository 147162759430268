<template>
  <div class="form-group">
    <v-select
      multiple
      :class="{ 'has-selection': value }"
      v-model="inputVal"
      :label="optionLabel ? optionLabel : 'text'"
      :options="options"
      :close-on-select="false"
      :reduce="reduce"
      :value="value"
      v-bind="$attrs"
    >
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </v-select>
    <label class="control-label" v-html="fieldLabel"></label><i class="bar"></i>
  </div>
</template>

<script>
  import vSelect from 'vue-select'; // https://vue-select.org/

  export default {
    name: 'MultiSelect',
    props: ['value', 'optionLabel', 'options', 'fieldLabel', 'reduce'],
    components: {
      vSelect
    },
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
  };
</script>
