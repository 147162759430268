<template>
  <div>
    <portal to="before-content">
      <div class="filter-bar rfs-p-8-l">
        <font-awesome-icon icon="filter" />
        <button
          type="button"
          class="btn btn-text"
          :class="[
            {
              active: smScreenControlsShow && filterGroup === 'characteristics'
            }
          ]"
          @click="setFilterGroup('characteristics')"
        >
          Characteristics <font-awesome-icon icon="angle-down" />
        </button>
        <button
          type="button"
          class="btn btn-text"
          :class="[
            { active: smScreenControlsShow && filterGroup === 'demographics' }
          ]"
          @click="setFilterGroup('demographics')"
          :disabled="disableComorbidityAndProcedures"
        >
          Demographics <font-awesome-icon icon="angle-down" />
        </button>
      </div>
    </portal>
    <h2 class="content-title-small rfs-m-4-b">
      Population Statistics:
      <span class="fs-20 fw-sb">{{ populationName }}</span>
      <button
        v-if="!newPopulation"
        type="button"
        class="btn btn-text btn-sm"
        @click="showRenamePrompt()"
      >
        Rename
      </button>
    </h2>
    <div>
      <div
        class="grid sm:grid-cols-12 items-center gap-8"
        :class="[{ 'form-disabled': disableControlsLoading }]"
      >
        <date-range
          v-model="dateRange"
          :disabled="disableControlsLoading"
          @input="onUpdateDate()"
          class="sm:col-span-3 md:col-span-12 lg:col-span-4 xl:col-span-3"
        />
        <!-- inline margin adjustment to align date and data segements -->
        <any-segment-select
          style="margin-top: 12px"
          :disableControls="disableControlsLoading"
          :hospitalOptions="hospitals"
          :diagnosisInsightsSelected="diagnosisInsightsSelected"
          class="sm:col-span-9 md:col-span-12 lg:col-span-8 xl:col-span-9"
          :disabledRWD="disableComorbidityAndProcedures"
        />
      </div>

      <data-counts-tabs
        :visitsFiltered="visitsFiltered"
        :patientsFiltered="patientsFiltered"
        :patientStatusCountsVisits="patientStatusCountsVisits"
        :patientStatusCountsPatients="patientStatusCountsPatients"
        :diagnosisInsightsCountsVisits="diagnosisInsightsCountsVisits"
        :ageRangeChoices="ageRangeChoices"
        :genderChoices="genderChoices"
        :patientStatusChoices="patientStatusChoices"
        :visitsLoading="visitsLoading"
        :patientsLoading="patientsLoading"
        @hideComorbidityAndProcedures="hideComorbidityAndProcedures"
        @showComorbidityAndProcedures="showComorbidityAndProcedures"
        @newPopulationLoading="newPopulationLoading"
        :ndcCodes="ndcCodes"
      />
    </div>
    <portal to="after-content">
      <!-- <div class="transition-container">
        <transition name="slide"> -->
      <!-- need to use v-show here or changing tabs causes choices to get lost -->
      <div
        v-show="filterGroup === 'characteristics'"
        id="popCharacteristics"
        class="right-side-bar popCriteria"
        :class="{
          'sm-screen-contols-show':
            smScreenControlsShow && !breakpoints.greaterThanMd,
          'sm-screen-contols': !breakpoints.greaterThanMd
        }"
      >
        <div class="rsb-content auto-scroll">
          <div class="criteria-header flex flex-wrap gap-4 items-center">
            <h2 class="content-title-small color-secondary float-left">
              Population Characteristics
            </h2>
            <div style="margin-left: auto">
              <button
                data-load-characteristics-btn
                type="button"
                class="btn btn-text"
                @click="
                  $modal.show('change-characteristics', null, {
                    height: 'auto'
                  })
                "
                :disabled="disableControlsLoading || disableComorbidityAndProcedures
                "
              >
                Load Characteristics
              </button>
              <button
                data-save-prompt
                class="btn btn-sm btn-primary rfs-m-4-l"
                :disabled="savePopulationRecord()"
                @click="showSavePrompt()"
              >
                <span v-if="newPopulation">Save</span>
                <span v-else>Save As</span>
              </button>
              <button
                data-save-no-prompt
                class="btn btn-sm btn-primary rfs-m-4-l"
                :disabled="savePopulationRecord()"
                @click="saveQueryFilters({ isNew: false, isRename: false })"
                v-if="!newPopulation"
              >
                Save
              </button>
            </div>
          </div>
          <form
            id="buildContent"
            @submit.prevent="updatePopulation"
            class="clear-both rfs-m-6-t"
          >
            <div class="grid xl:grid-cols-2 gap-6">
              <div>
                <h3 class="fs-18 fw-600 rfs-m-6-b">Diagnoses</h3>
                <p class="rfs-m-6-b" v-if="diagnosesFilters.length === 0">
                  None added.
                </p>
                <div
                  v-for="(filter, i) in diagnosesFilters"
                  :key="filter.localKey"
                  class="pop-filters"
                >
                  <component
                    :is="filter.fieldComponent"
                    v-bind="filter.props"
                    :filterKey="filter.localKey"
                    @empty-cancel="removeFilter(filter.localKey)"
                    @delete="removeFilter(filter.localKey, true)"
                    @swap-filter="swapFilter"
                    @operator="updateOperator"
                    @update-population="updatePopulation"
                    :disabled="disableControlsLoading"
                  />
                  <span v-if="i != filters.length - 1">and</span>
                </div>
                <div class="builder-controls rfs-m-6-t">
                  <!-- prettier-ignore -->
                  <div
                    v-if="!filterTypeCounts.principalDiagnosis ||filterTypeCounts.principalDiagnosis === 0"
                  >
                    <button
                      data-test="add-principle-diagnosis"
                      type="button"
                      class="btn btn-text rfs-m-2-b"
                      @click="addCriterion('principalDiagnosis')"
                      :disabled="disableControlsLoading || disableComorbidityAndProcedures"
                    ><span class="flex items-center gap-2">
                      <font-awesome-icon icon="plus-square" class="fs-36" /><span
                        >Principal Diagnosis</span
                      >
                    </span>
                    </button>
                  </div>
                  <div>
                    <button
                      data-test="add-diagnosis-group"
                      type="button"
                      class="btn btn-text flex items-center gap-2 rfs-m-2-b"
                      @click="addCriterion('diagnosisGroup')"
                      :disabled="disableControlsLoading || disableComorbidityAndProcedures
                      "
                    >
                      <span class="flex items-center gap-2">
                        <font-awesome-icon
                          icon="plus-square"
                          class="fs-36"
                        /><span>Diagnosis Group</span></span
                      >
                    </button>
                  </div>
                  <div>
                    <button
                      data-test="add-comorbidity"
                      type="button"
                      class="btn btn-text flex items-center gap-2 rfs-m-2-b"
                      @click="addCriterion('comorbidity')"
                      :disabled="
                        disableControlsLoading ||
                        disableComorbidityAndProcedures
                      "
                    >
                      <span class="flex items-center gap-2">
                        <font-awesome-icon
                          icon="plus-square"
                          class="fs-36"
                        /><span>Comorbidity</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <h3 class="fs-18 fw-600 rfs-m-6-b">Treatment Regimen</h3>
                <p
                  class="rfs-m-6-b"
                  v-if="treatmentRegimenFilters.length === 0"
                >
                  None added.
                </p>
                <div
                  v-for="(filter, i) in treatmentRegimenFilters"
                  :key="filter.localKey"
                  class="pop-filters"
                >
                  <component
                    :is="filter.fieldComponent"
                    v-bind="filter.props"
                    :filterKey="filter.localKey"
                    @empty-cancel="removeFilter(filter.localKey)"
                    @delete="removeFilter(filter.localKey, true)"
                    @operator="updateOperator"
                    @swap-filter="swapFilter"
                    @update-population="updatePopulation"
                    :disabled="disableControlsLoading"
                  />
                  <span v-if="i != filters.length - 1">and</span>
                </div>
                <div class="builder-controls rfs-m-6-t">
                  <!-- prettier-ignore -->
                  <div>
                    <button
                      data-test="add-therapeutic-class"
                      type="button"
                      class="btn btn-text flex items-center gap-2 rfs-m-2-b"
                      @click="addCriterion('therapeuticClass')"
                      :disabled="disableControlsLoading || disableComorbidityAndProcedures"
                    ><span class="flex items-center gap-2">
                      <font-awesome-icon icon="plus-square" class="fs-36" /><span
                        >Therapeutic Class</span
                      ></span>
                    </button>
                  </div>
                  <div>
                    <button
                      data-test="add-medication-group"
                      type="button"
                      class="btn btn-text flex items-center gap-2 rfs-m-2-b"
                      @click="addCriterion('drugGroups')"
                      :disabled="disableControlsLoading"
                    >
                      <span class="flex items-center gap-2">
                        <font-awesome-icon
                          icon="plus-square"
                          class="fs-36"
                        /><span>Medication Group</span></span
                      >
                    </button>
                  </div>
                  <div>
                    <button
                      data-test="add-medication-by-ndc"
                      type="button"
                      class="btn btn-text flex items-center gap-2 rfs-m-2-b"
                      @click="addCriterion('ndc')"
                      :disabled="disableControlsLoading"
                    >
                      <span class="flex items-center gap-2">
                        <font-awesome-icon
                          icon="plus-square"
                          class="fs-36"
                        /><span>Medication by NDC</span></span
                      >
                    </button>
                  </div>
                  <div>
                    <button
                      data-test="add-procedures"
                      type="button"
                      class="btn btn-text flex items-center gap-2 rfs-m-2-b"
                      @click="addCriterion('procedureCodes')"
                      :disabled="
                        disableControlsLoading ||
                        disableComorbidityAndProcedures
                      "
                    >
                      <span class="flex items-center gap-2">
                        <font-awesome-icon
                          icon="plus-square"
                          class="fs-36"
                        /><span>Procedures</span></span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- Delete button -->
          <div
            class="criteria-header flex flex-wrap gap-4 items-center"
            v-if="!newPopulation"
          >
            <div style="margin-left: auto">
              <button
                data-delete-population
                class="btn btn-sm btn-danger rfs-m-4-l"
                @click="deletePopulation"
              >
                <span>Delete Population</span>
              </button>
            </div>
          </div>
        </div>
        <div
          class="mobile-filter-footer flex items-center"
          v-if="!breakpoints.greaterThanMd"
        >
          <!-- prettier-ignore -->
          <button
            type="button"
            class="btn btn-text float-right flex-last-to-right"
            @click="smScreenControlsShow = false;">
            View Statistics
          </button>
        </div>
      </div>
      <!-- </transition>
        <transition name="slide"> -->
      <!-- need to use v-if here or choices don't populate -->
      <div
        v-if="filterGroup === 'demographics'"
        id="popDemographics"
        class="right-side-bar popCriteria"
        :class="{
          'sm-screen-contols-show':
            smScreenControlsShow && !breakpoints.greaterThanMd,
          'sm-screen-contols': !breakpoints.greaterThanMd
        }"
      >
        <div class="rsb-content auto-scroll">
          <h2 class="content-title-small color-secondary float-left">
            Population Demographics
          </h2>
          <form
            id="buildContent"
            @submit.prevent="updatePopulation"
            class="clear-both rfs-m-12-t"
          >
            <fieldset class="pop-filters">
              <checkbox-select
                v-model="genderChoices"
                :options="genders"
                optionLabel="description"
                optionValue="code"
                fieldLabel="Gender"
              ></checkbox-select>

              <checkbox-select
                v-model="ageRangeChoices"
                :options="ageRanges"
                optionLabel="description"
                optionValue="code"
                fieldLabel="Age Ranges"
              ></checkbox-select>
            </fieldset>
          </form>
        </div>
        <div
          class="mobile-filter-footer flex items-center"
          v-if="!breakpoints.greaterThanMd"
        >
          <!-- prettier-ignore -->
          <button
                type="button"
                class="btn btn-text float-right flex-last-to-right"
                @click="smScreenControlsShow = false">
                View Statistics
              </button>
        </div>
      </div>
      <!-- </transition>
      </div> -->

      <!-- models -->
      <modal name="save-dialog-rename">
        <form
          class="rfs-m-6"
          @submit.prevent="saveQueryFilters({ isNew: false, isRename: true })"
        >
          <h3 class="modal-title">Rename Population</h3>
          <div
            class="form-group form-row"
            :class="getValidationClass('populationNameLocal')"
          >
            <input
              :class="{ 'has-value': populationNameLocal }"
              id="populationNameLocal"
              type="text"
              v-model.trim="$v.populationNameLocal.$model"
              :disabled="disableControlsLoading"
              maxlength="60"
            />
            <label class="control-label" for="populationNameLocal"
              >Name
              <font-awesome-icon
                v-if="
                  !$v.populationNameLocal.$invalid &&
                  $v.populationNameLocal.$dirty
                "
                icon="check"
                class="color-success" /></label
            ><i class="bar"></i>
            <p class="error-message" v-if="!$v.populationNameLocal.required">
              A name is required to rename your population.
            </p>
          </div>
          <div class="modal-buttons">
            <button type="button" class="btn" @click="cancelSave">
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-primary float-right"
              :disabled="disableControlsLoading || !populationNameLocal"
            >
              Save
            </button>
          </div>
        </form>
      </modal>

      <modal name="save-dialog-new">
        <form
          class="rfs-m-6"
          @submit.prevent="saveQueryFilters({ isNew: true, isRename: false })"
        >
          <h3 class="modal-title">
            <span v-if="newPopulation">Save</span> <span v-else>Save As</span>
          </h3>
          <p class="fs-14" v-if="newPopulation">
            Save the Population Characteristics
          </p>
          <p class="fs-14" v-if="!newPopulation">
            Save a copy of the Population Characteristics
          </p>
          <div
            class="form-group form-row"
            :class="getValidationClass('populationNameLocal')"
          >
            <input
              :class="{ 'has-value': populationNameLocal }"
              id="populationNameLocal"
              type="text"
              v-model.trim="$v.populationNameLocal.$model"
              :disabled="disableControlsLoading"
              @focus="$event.target.select()"
              maxlength="60"
            />
            <label class="control-label" for="populationNameLocal"
              ><span v-if="newPopulation">Name</span>
              <span v-else>Name of Copy</span>
              <font-awesome-icon
                v-if="
                  !$v.populationNameLocal.$invalid &&
                  $v.populationNameLocal.$dirty
                "
                icon="check"
                class="color-success" /></label
            ><i class="bar"></i>
            <p class="error-message" v-if="!$v.populationNameLocal.required">
              A name is required to save your population.
            </p>
          </div>
          <div class="modal-buttons">
            <button type="button" class="btn" @click="cancelSave">
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-primary float-right"
              :disabled="disableControlsLoading || !populationNameLocal"
            >
              Save
            </button>
          </div>
        </form>
      </modal>

      <modal name="switch-pop-confirm">
        <div class="rfs-m-6">
          <h3 class="modal-title">Save changes before proceeding?</h3>
          <p class="card bg-warning-contrast color-white fw-600">
            <font-awesome-icon class="" icon="exclamation-circle" />
            You are about to navigate away from the population without saving
            changes you've made. If you proceed without saving all changes will
            be lost.
          </p>
          <button class="btn btn-text float-right" @click="changePopulation()">
            Proceed without saving
          </button>
          <div class="modal-buttons flex flex-wrap gap-1 items-center">
            <button
              type="button"
              class="btn"
              @click="$modal.hide('switch-pop-confirm')"
            >
              Cancel
            </button>
            <div
              style="margin-left: auto"
              class="flex flex-wrap gap-1 items-center"
            >
              <button
                v-if="!newPopulation"
                class="btn btn-secondary"
                @click="changePopulationsSaveAs()"
              >
                Save as
              </button>
              <button
                v-if="!newPopulation"
                class="btn btn-primary"
                @click="changePopulationsSave()"
              >
                Save
              </button>
              <button
                v-if="newPopulation"
                class="btn btn-primary"
                @click="changePopulationsSaveAs()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </modal>

      <modal name="change-characteristics">
        <div class="rfs-m-6">
          <h3 class="modal-title">
            Load a different set of population characteristics
          </h3>
          <single-select
            :options="savedPopulations"
            :getOptionLabel="(opt) => opt.filterData.populationName"
            optionValue="id"
            fieldLabel="My Population Characteristics"
            :clearable="false"
            class="rfs-m-8-t"
            style="width: 100%"
            v-model="savedPopulationValue"
          />
          <div class="modal-buttons">
            <button
              type="button"
              class="btn"
              @click="$modal.hide('change-characteristics')"
            >
              Cancel
            </button>
          </div>
        </div>
      </modal>
    </portal>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';

  import sumBy from 'lodash/sumBy';

  // Filter Choice Data Bits
  import {
    calculatePatientStatusDataVisits,
    calculatePatientStatusDataPatients,
    genders,
    ageRanges
  } from '@/data';

  // new composition AIP bits for long-burn refactor as we update
  import { watch, inject, ref, computed } from '@vue/composition-api';
  import { filterArray } from '@/js/utilities';

  // old bits to phase out
  import criteriaBuilder from '@/mixins/populations/criteriaBuilder_v2';

  // Lazy loaded webpack chunks
  const AnySegmentSelect = () =>
    import('@/components/filters/AnySegmentSelect.vue');
  const dateRange = () => import('@/components/base/forms/date_range');
  const CheckboxSelect = () =>
    import('@/components/base/forms/checkbox_select.vue');
  const DataCountsTabs = () =>
    import('@/components/popbuilder/DataCountsTabs.vue');

  export default {
    name: 'PopBuilder',
    // new composition AIP bits for long-burn refactor as we update
    setup(props, { root }) {
      const populationStore = inject('populationStore');
      const workflowStore = inject('populationWorkflowStore');

      let visitsFiltered = ref([]);
      let patientsFiltered = ref([]);

      let visitsLoading = ref(false);
      let patientsLoading = ref(false);

      let ndcCodes = ref([]);

      // ***
      //    Populations
      const patientStatusCountsVisits = computed(() => {
        let counts = calculatePatientStatusDataVisits(visitsFiltered.value);
        counts.all = counts.inpatient + counts.outpatient;
        return counts;
      });

      const patientStatusCountsPatients = computed(() => {
        let counts = calculatePatientStatusDataPatients(patientsFiltered.value);
        // since a patient can be both in a given time frame, this is not additive like it is for visits
        counts.all = sumBy(patientsFiltered.value, 'count');
        return counts;
      });

      const diagnosisInsightsCountsVisits = computed(() => {
        let counts = calculatePatientStatusDataVisits(visitsFiltered.value);
        counts.all = counts.inpatient + counts.outpatient;
        return counts;
      });

      // ***
      //    Watchers

      // Working indicator
      watch(populationStore.visitsLoading, (currentValue, oldValue) => {
        visitsLoading.value = currentValue;
      });

      watch(populationStore.patientsLoading, (currentValue, oldValue) => {
        patientsLoading.value = currentValue;
      });

      // Cause the population to update when dirty
      watch(populationStore.isDirty, (currentValue, oldValue) => {
        if (currentValue) {
          populationStore.loadPopulation();
        }
      });

      // Cause the population to locally update when local dirty
      watch(populationStore.isDirtyLocal, (currentValue, oldValue) => {
        if (currentValue) {
          visitsFiltered.value = filterArray(
            populationStore.getVisitData(),
            workflowStore.demographicFiltersVisits
          );

          patientsFiltered.value = filterArray(
            populationStore.getPatientData(),
            workflowStore.demographicFiltersPatients
          );
        }

        populationStore.setDirtyLocal(false);
      });

      // When the visit data is updated, filter and display
      watch(populationStore.getVisitData, (currentValue, oldValue) => {
        visitsFiltered.value = filterArray(
          currentValue,
          workflowStore.demographicFiltersVisits
        );
      });

      // When the population data is updated, filter and display
      watch(populationStore.getPatientData, (currentValue, oldValue) => {
        patientsFiltered.value = filterArray(
          currentValue,
          workflowStore.demographicFiltersPatients
        );
      });

      // When the population data is updated, filter and display
      watch(workflowStore.getDataSegment, (currentValue, oldValue) => {
        populationStore.loadPopulation();
      });

    // When the population filters is updated, filter and display
    watch(workflowStore.getFilters, (currentValue, oldValue) => {
      const medByNdc = currentValue.filter(characterstic => characterstic.name === 'ndc');
      if (medByNdc.length > 0) {
        ndcCodes.value = medByNdc[0].valueCodes.filter(ndcObj => ndcObj.codeType === undefined)
          .map(ndcObj => ndcObj.code);
      } else if (medByNdc.length === 0 && ndcCodes.value.length > 0) {
        ndcCodes.value = [];
      }
    });

      return {
        visitsFiltered,
        patientsFiltered,
        patientStatusCountsVisits,
        patientStatusCountsPatients,
        diagnosisInsightsCountsVisits,
        visitsLoading,
      patientsLoading,
      ndcCodes
    };
  },
  mixins: [criteriaBuilder, validationMixin],
  inject: ['populationStore', 'populationWorkflowStore'],
  components: {
    AnySegmentSelect,
    dateRange,
    CheckboxSelect,
    DataCountsTabs
  },
  data() {
    return {
      unwatchDataSegmentSelected: null,
      unwatchPatientsLoading: null,
      pageReady: false,
      hospitalList: false,
      smScreenControlsShow: false,
      filterGroup: 'characteristics',
      genders: genders,
      ageRanges: ageRanges,
      dateRangeLocal: null,
      newPopulation: true,
      savedPopulations: [],
      savedPopulationValue: '',
      switchingPopulationTo: null,
      populationId: null,
      disableComorbidityAndProcedures: false,
      diagnosisInsightsSelected:false,
        diagnosisInsightsSelected: false
      };
    },
    validations: {
      populationNameLocal: {
        required
      }
    },
    methods: {
      async init() {
        let alreadyInUse = this.populationStore.isInUse();
        this.populationStore.setInUse(true);
        this.populationWorkflowStore.setInUse(true);

        // If this population isn't already setup, have to set the base
        this.populationStore.LoadDefaultCriteria(false); // Load the default demographics (but don't overwrite if it's already set)
        this.resetCriteria(); // Clear anything that is already in the UI and reset the local (non-store) variables

        this.populationStore.resetPreviousRequest();

        // If we have a saved population, need to get it
        if (this.$route.params.popid) {
          this.newPopulation = false;
          this.populationId = this.$route.params.popid;

          // Get the saved population from the API
          await this.populationStore.getSavedPopulationById(
            this.$route.params.popid
          );

          this.populationCriteriaLocal =
            this.populationWorkflowStore.getFilters(); // Load the saved population to the local (non-store) variables
          this.loadCriteria(this.populationWorkflowStore.getFilters()); // Add the filter boxes to the UI
          this.populationNameLocal = this.populationStore.getName(); // Save the population name to the local variables, and display in the UI

          this.populationStore.setPopulationReady();
        } else {
          this.populationWorkflowStore.resetPopulationFilters();
          this.newPopulation = true;
          this.populationNameLocal = 'Unnamed Population';

          if (!alreadyInUse) {
            this.populationStore.reset(); // Reset the population to the base
          } else {
            this.loadCriteria(this.populationWorkflowStore.getFilters()); // Add the filter boxes to the UI
          }

          // Get the starting population data
          this.populationStore.setPopulationReady();
          this.populationStore.loadPopulation();
        }

        // Browser tab title
        document.title =
          this.$route.meta.title + ': ' + this.populationNameLocal;
      },
      swapFilter(data) {
        this.loadCriteria(data);
        // for some reason it take a moment for the data to get into the store object
        // so we fake loading the UI early to allow things to work
        this.visitsLoading = true;
        this.patientsLoading = true;
        // and we wait a moment to update the population
        setTimeout(() => {
          this.updatePopulation();
        }, 1000);
      },
      populateSavedPopulationList(setValue) {
        this.$store
          .dispatch('populationManagement/getSavedPopulationsList')
          .then(() => {
            let d = this.$store.getters['populationManagement/getSavedList'];

            this.savedPopulations = d;

            // If we have a saved population loaded, need to select it
            if (this.$route.params.popid) {
              for (let i in d) {
                if (d[i].id == this.$route.params.popid) {
                  if (setValue) {
                    this.savedPopulationValue = setValue;
                  } else {
                    this.savedPopulationValue = d[i];
                  }
                }
              }
            }
          });
      },
      isPopulationSaved() {
        return this.populationStore.isSaved();
      },
      showSavePrompt() {
        this.$modal.show('save-dialog-new', null, { height: 'auto' });
      },
      saveSuccess() {
        this.$modal.hide('save-dialog-rename');
        this.$modal.hide('save-dialog-new');
      },
      cancelSave() {
        this.populationNameLocal = this.$route.params.popid
          ? this.populationStore.getName()
          : 'Unnamed Population';
        this.$modal.hide('save-dialog-rename');
        this.$modal.hide('save-dialog-new');
      },
      showRenamePrompt() {
        this.$modal.show('save-dialog-rename', null, { height: 'auto' });
      },
      changePopulation() {
        this.$modal.hide('switch-pop-confirm');
        this.$modal.hide('save-dialog-new');
        this.populateSavedPopulationList(this.savedPopulationValue);
        this.$router.push({
          name: 'PopulationBuilder',
          params: { popid: this.switchingPopulationTo }
        });
        this.switchingPopulationTo = null;
      },
      changePopulationsSave() {
        this.$modal.hide('switch-pop-confirm');
        this.saveQueryFilters({ isNew: false, isRename: false });
      },
      changePopulationsSaveAs() {
        this.$modal.hide('switch-pop-confirm');
        this.showSavePrompt();
      },
      getValidationClass(fieldName) {
        const field = this.$v[fieldName];
        if (field) {
          return {
            'has-error': field.$invalid && field.$dirty
          };
        }
      },
      onUpdateDate() {
        this.updatePopulation();
      },
      setFilterGroup(group) {
        // if panel open and group changes
        //   no toggle and change group

        // if panel open and group doesn't change
        //   close panel and no change to group

        // if panel closed and group changes
        //   open panel and change group

        // if panel closed and group doesn't change
        //   open panel no change to group

        if (this.smScreenControlsShow) {
          if (this.filterGroup !== group) {
            this.filterGroup = group;
          }
        } else {
          if (this.filterGroup !== group) {
            this.filterGroup = group;
          }
          this.smScreenControlsShow = true;
        }
      },
      toggleControls() {
        this.smScreenControlsShow = !this.smScreenControlsShow;
      },

      // John Start

      // Submitting the form around the characteristics (?)
      updatePopulation() {
      // retrieve ndcCodes
      const medByNdc = this.populationCriteriaLocal.filter(characterstic => characterstic.name === 'ndc');
      if (medByNdc.length > 0) {
        this.ndcCodes = medByNdc[0].valueCodes.filter(ndcObj => ndcObj.codeType === undefined)
          .map(ndcObj => ndcObj.code);
      }
        this.populationWorkflowStore.setFilters(this.populationCriteriaLocal);
        this.populationStore.recalculateSavedFlag();
        this.populationStore.setDirty(true);
      },
      deletePopulation() {
        const popObj = {
          type: 'population',
          id: this.populationId
        };
        window.vm.genericDelete(escape(JSON.stringify(popObj)));
      },
      hideComorbidityAndProcedures() {
        if (this.populationWorkflowStore.getDataSegment().mode === 'rwd') {
          this.populationWorkflowStore.setDataSegment({
          selected: true,
          mode: 'hospital',
          value: Array.of((this.hospitals[0]))
        })
        this.diagnosisInsightsSelected=true;
        this.populationStore.loadVisitDataForDiagnosisInsights();
     }
      this.disableComorbidityAndProcedures = true;
    },
    showComorbidityAndProcedures() {
      this.diagnosisInsightsSelected=false;

        this.disableComorbidityAndProcedures = false;
      },
      savePopulationRecord() {
      if (this.disableComorbidityAndProcedures) {
        return true;
      } else if (this.isPopulationSaved() || this.disableControlsLoading) {
        return true;
      } else return false;
    },
     newPopulationLoading() {
       if (this.disableComorbidityAndProcedures ) {
        this.resetCriteriaPartially(); // Clear anything that is already in the UI and reset the local (non-store) variables
        this.populationWorkflowStore.resetFiltersExcludingNdcs();
        this.newPopulation = true;
        this.populationNameLocal = 'Unnamed Population';
      }else if(this.$route.params.popid && this.newPopulation) {
        this.init();
      }
      }
  },
  computed: {
    disableControlsLoading() {
      return this.visitsLoading || this.patientsLoading;
    },
    populationName() {
      return this.populationNameLocal || 'Unnamed Population';
    },
    ageRangeChoices: {
      get() {
        return this.populationWorkflowStore.getAgeRange();
      },
      set(value) {
        this.populationWorkflowStore.setAgeRange(value);
      }
    },
    // Model for the gender checkboxes
    // Also sent to the graph
    genderChoices: {
      get() {
        return this.populationWorkflowStore.getGender();
      },
      set(value) {
        this.populationWorkflowStore.setGender(value);
      }
    },

      // Model for the date range fields
      dateRange: {
        get() {
          return this.populationWorkflowStore.getDateRange();
        },
        set(value) {
          this.populationWorkflowStore.setDateRange(value);
        }
      },
      // Model for the patient status fields
      patientStatusChoices: {
        get() {
          return this.populationWorkflowStore.getPatientStatus();
        },
        set(value) {
          this.populationWorkflowStore.setPatientStatus(value);
        }
      },

      // Creates the data structure for characterstics updates (?)
      popRequestParam() {
        let innerData = this.populationCriteriaLocal;
        let fullData = {
          logicalOperator: 'and',
          rules: innerData
        };

        return JSON.stringify(fullData);
      },

      // John End
      hospitals() {
        let hospitals = this.$store.getters['auth/userHospitalList'];
        let options = [];

        for (let i in hospitals) {
          options.push({ text: hospitals[i].name, value: hospitals[i].id });
        }

        return options;
      },
      diagnosesFilters() {
        // not the best way, but until we get the real split of filters on the API side, a shim to make it work
        return this.filters.filter(
          (d) =>
            d.name === 'principalDiagnosis' ||
            d.name === 'comorbidity' ||
            d.name === 'diagnosisGroup'
        );
      },
      treatmentRegimenFilters() {
        // not the best way, but until we get the real split of filters on the API side, a shim to make it work
        return this.filters.filter(
          (d) =>
            d.name !== 'principalDiagnosis' &&
            d.name !== 'comorbidity' &&
            d.name !== 'diagnosisGroup'
        );
      }
    },
    watch: {
      '$route.params.popid': function (id) {
        this.init();
      },
      populationNameLocal() {
        document.title =
          this.$route.meta.title + ': ' + this.populationNameLocal;
      },
      savedPopulationValue() {
        this.$modal.hide('change-characteristics');
        if (this.savedPopulationValue.id != this.$route.params.popid) {
          this.switchingPopulationTo = this.savedPopulationValue.id;

          if (this.isPopulationSaved()) {
            this.changePopulation();
          } else {
            this.$modal.show('switch-pop-confirm', null, { height: 'auto' });
          }
        }
      }
    },
    mounted() {
      this.init();
      this.populateSavedPopulationList();
    }
  };
</script>

<style lang="scss">
  @use '@/assets/scss/responsive';
  @use './node_modules/include-media/dist/include-media.scss' as im with
    ($breakpoints: responsive.$breakpoints);
  @use '@/assets/scss/vars.scss' as v;
  @use '@/assets/scss/population_builder';
  .popCriteria {
    .rsb-content {
      margin-top: v.$filterBarHeight;
    }
  }
  .modal-buttons {
    position: absolute;
    bottom: 0;
    margin: 16px 0;
    width: calc(100% - 48px);
  }

  #buildContent {
    margin-bottom: 150px;
  }

  .criteria-header {
    position: sticky;
    top: -32px;
    z-index: 1;
    background: v.$surfaceBackground;
    background: linear-gradient(
      180deg,
      v.$surfaceBackground 60%,
      transparent 100%
    );
    width: auto;
    height: auto;
    overflow: auto;
    padding: 32px;
    margin: -32px -32px;
  }

  .gender-profile-chart,
  .status-profile-chart {
    canvas {
      height: 175px !important;
    }
  }

  .age-by-gender-profile-chart {
    canvas {
      height: 250px !important;
    }
  }

  @include im.media('<md') {
    // need to use small to override large as this only happens if filters exsit at the page level.
    .population-builder {
      #appContent {
        min-height: calc(100vh - 198px);
        margin-top: v.$headerHeight + v.$filterBarHeight;
      }
    }
  }

  @include im.media('>=md') {
    .population-builder {
      .filter-bar {
        width: 45vw;
        right: 0;
      }
      .popCriteria {
        width: 45vw;

        .rsb-content {
          margin-top: auto;
          margin-top: v.$headerHeight;
        }
      }
    }
  }
</style>
