<template>
  <fieldset class="form-expanded-choice">
    <legend class="feild-label">{{ fieldLabel }}</legend>
    <div
      class="checkbox"
      :class="{ 'as-row': asRow }"
      v-for="(option, index) in options"
      :key="index"
    >
      <label>
        <input
          v-model="inputVal"
          :value="getOptionValue(option)"
          type="checkbox"
          :name="name"
        /><i class="helper"></i>{{ getOptionLabel(option) }}
      </label>
    </div>
  </fieldset>
</template>

<script>
  export default {
    name: 'CheckboxSelect',
    props: {
      name: {
        type: String,
        require: true,
        default() {
          return 'checkbox_' + Math.floor(Math.random() * 100 + 1);
        }
      },
      value: {
        type: Array,
        default: null
      },
      asRow: {
        type: Boolean
      },
      optionLabel: {
        type: String,
        default: 'text'
      },
      options: {
        type: Array,
        default() {
          return [];
        },
        required: true
      },
      optionValue: {
        type: String,
        default: 'value'
      },
      fieldLabel: {
        type: String
      },
      getOptionLabel: {
        type: Function,
        default(option) {
          if (typeof option === 'object') {
            if (
              !Object.prototype.hasOwnProperty.call(option, this.optionLabel)
            ) {
              return console.warn(
                `[vue-select warn]: Label key "option.${this.optionLabel}" does not` +
                  ` exist in options object ${JSON.stringify(option)}.`
              );
            }
            return option[this.optionLabel];
          }
          return option;
        }
      },
      getOptionValue: {
        type: Function,
        default(option) {
          if (typeof option === 'object') {
            if (
              !Object.prototype.hasOwnProperty.call(option, this.optionValue)
            ) {
              return console.warn(
                `[vue-select warn]: Value key "option.${this.optionValue}" does not` +
                  ` exist in options object ${JSON.stringify(option)}.`
              );
            }
            return option[this.optionValue];
          }
          return option;
        }
      }
    },
    data() {
      return {
        temp_value: this.value
      };
    },
    computed: {
      inputVal: {
        get() {
          return this.temp_value;
        },
        set(val) {
          this.temp_value = val;
          this.$emit('input', val);
        }
      }
    }
  };
</script>
