<template>
  <fieldset style="position: relative">
    <span
      title="remove"
      class="fs-20 fw-700"
      style="position: absolute; right: 8px; top: 0; cursor: pointer"
      v-on:click="$emit('delete')"
      >x</span
    >
    <legend>{{ fieldLabel }}</legend>
    <div class="flex">
      <single-select
        class="lg:col-span-3 flex-1"
        v-model="operatorVal"
        :options="operatorOptions"
        :clearable="false"
        required
        @input="
          $emit('operator', operatorVal, filterKey);
          $emit('update-population');
        "
      />
      <button
        type="button"
        class="btn btn-primary flex-1 rfs-m-4-l"
        @click="
          showModal();
          storeResetValue();
          checkForChanges();
        "
      >
        <span v-if="!this.inputVal.inputVal">Select</span
        ><span v-else>Edit</span> Codes
      </button>
    </div>
    <portal to="modal">
      <modal
        data-test="add-procedures-modal"
        :name="modalName"
        width="100%"
        height="auto"
        :adaptive="true"
        classes="rfs-p-8"
        :clickToClose="false"
      >
        <h3 class="modal-title">
          <span v-if="!this.inputVal.inputVal">Select</span
          ><span v-else>Edit</span> Procedures
        </h3>
        <code-lookup
          :value="value"
          v-bind="lookupProps"
          v-model.trim="inputVal"
          @input="checkForChanges()"
        >
        </code-lookup>
        <button
          type="button"
          class="float-left btn rfs-m-8-t"
          @click="
            hideModal();
            cancelSelection();
          "
        >
          Cancel
        </button>
        <button
          :disabled="noChange"
          type="button"
          class="float-right btn rfs-m-8-t"
          @click="updateStatistics()"
        >
          Update Statistics
        </button>
        <footer id="modalFooter">
          <br />
          <p>
            CPT copyright 2019 American Medical Association. All rights
            reserved.
          </p>
          <p>
            Fee schedules, relative value units, conversion factors and/or
            related components are not assigned by the AMA, are not part of CPT,
            and the AMA is not recommending their use. The AMA does not directly
            or indirectly practice medicine or dispense medical services. The
            AMA assumes no liability for data contained or not contained herein.
          </p>
          <p>
            CPT is a registered trademark of the American Medical Association
          </p>
        </footer>
      </modal>
    </portal>
    <ul class="rfs-m-4-t fs-14">
      <li v-for="(selected, i) in inputVal.inputVal" :key="i">
        {{ selected.field2 }}: {{ selected.code }}: {{ selected.description }}
        <div v-if="i != inputVal.inputVal.length - 1">{{ joiningBy }}</div>
      </li>
    </ul>
    <footer id="cptCopyright">
      CPT copyright 2019 American Medical Association. All rights reserved.
    </footer>
  </fieldset>
</template>

<script>
  import selectStore from '../../../store/modules/global/filters/select';
  import singleSelect from '../../../components/base/forms/single_select';
  import CodeLookup from '../CodeLookup';
  import isEqual from 'lodash/isEqual';

  export default {
    name: 'NdcSelectMagic',
    components: {
      CodeLookup,
      singleSelect
    },
    props: {
      filterKey: Number,
      modalName: {
        type: String,
        default: 'pc_code_modal_' + Math.floor(Math.random() * 1000 + 1)
      },
      value: {
        type: Object
      },
      moduleNamespace: {
        type: String,
        required: true
      },
      codeTypes: {
        type: Array,
        default() {
          return [
            {
              id: 'procedures-code',
              apiPath: 'procedures-code',
              name: 'procedureCodes'
            }
          ];
        }
      },
      fieldLabel: {
        type: String
      },
      operator: {
        type: String,
        default: 'includes'
      },
      logicalOperator: {
        type: String,
        default: 'or'
      }
    },
    data() {
      return {
        lookupProps: {
          searchLabel: 'Procedures Lookup',
          codeTypes: this.codeTypes,
          uniqueIdentifier: 'code',
          columns: [
            {
              text: 'Code',
              datafield: 'code'
            },
            {
              text: 'Description',
              datafield: 'description'
            },
            {
              text: 'Code Type (CPT<sup style="font-size:75%">&reg;</sup>, HCPCS, ICD10P)',
              datafield: 'field2',
              width: 250
            }
          ],
          initialSort: {
            column: 'description',
            direction: 'asc'
          }
        },
        operatorOptions: ['includes', 'excludes'],
        operatorVal: this.operator,
        noChange: true
      };
    },
    computed: {
      inputVal: {
        get() {
          return this.$store.getters[this.moduleNamespace + '/getValue'];
        },
        set(value) {
          return this.$store.commit(this.moduleNamespace + '/setValue', value);
        }
      },
      joiningBy() {
        return this.logicalOperator;
      }
    },
    methods: {
      storeResetValue() {
        this.resetValue =
          this.$store.getters[this.moduleNamespace + '/getValue'];
      },
      checkForChanges() {
        var selected = this.inputVal.inputVal.map(function (item) {
          return item.code;
        });
        var reset = this.resetValue.inputVal.map(function (item) {
          return item.code;
        });
        this.noChange = isEqual(selected, reset);
      },
      cancelSelection() {
        this.inputVal = this.resetValue;
        if (
          !this.inputVal ||
          !this.inputVal.inputVal ||
          this.inputVal.inputVal.length === 0
        ) {
          this.$emit('empty-cancel');
        }
      },
      updateStatistics() {
        if (
          !this.inputVal ||
          !this.inputVal.inputVal ||
          this.inputVal.inputVal.length === 0
        ) {
          this.hideModal();
          this.$emit('delete');
        } else {
          this.hideModal();
          this.$emit('update-population');
        }
      },
      showModal() {
        const modalName = this.modalName;
        this.$modal.show(modalName);
      },
      hideModal() {
        const modalName = this.modalName;
        this.$modal.hide(modalName);
        this.$emit('scroll-check');
      }
    },
    created() {
      this.magicModule(this.moduleNamespace, selectStore);
      this.$store.commit(this.moduleNamespace + '/setValue', this.value);
    },
    mounted() {
      if (
        !this.inputVal ||
        !this.inputVal.inputVal ||
        this.inputVal.inputVal.length === 0
      ) {
        // got to give the portal a spit second to load up
        var _this = this;
        setTimeout(function () {
          _this.storeResetValue();
          _this.showModal();
          _this.$watch('inputVal', (n) => {
            _this.checkForChanges();
          });
        }, 200);
      }
    }
  };
</script>

<style scoped>
  #modalFooter {
    clear: both;
    font-size: 9px;
    color: #999;
    padding-top: 10px;
  }

  #modalFooter p {
    padding-bottom: 0.3rem;
    line-height: 1rem;
  }

  #cptCopyright {
    font-size: 9px;
    color: #999;
    padding-top: 5px;
    margin-top: 15px;
    border-top: 1px solid #666;
  }
</style>
